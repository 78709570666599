/* eslint-disabled */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
// import { useCenteredTree } from './helpers';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  Button,
  Checkbox,
  Slider,
  Stepper,
  Typography,
  StepContent,
  StepLabel,
  Step,
  TextareaAutosize,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  IconButton
} from '@mui/material';
import './styles.css';
import { createQuestions, getQuestionByDisease, updateQuestions } from 'store/redux/actions/questions';
import { useLocation, useNavigate } from 'react-router';
import makeToast from 'utils/Toaster';
import { Form, Modal } from 'react-bootstrap';
import CustomOptionsTable from './CustomOptionsTable';
import CustomPreQuestionTable from './CustomPreQuestionTable';
import { Constants } from '../../../constants/Constants';

import ReactFlow, { Controls, Background, applyNodeChanges, applyEdgeChanges, MiniMap, Position } from 'reactflow';
import 'reactflow/dist/base.css';
import QuestionNode from './QuestionNode';
import OptionNode from './OptionNode';
import OptionDeadNode from './OptionDeadNode';
import OptionToolbar from './OptionToolbar';
import QuestionToolbar from './QuestionToolbar';
import { Panel } from 'reactflow/dist/esm';
import MultiLanguageInputField from 'ui-component/MultiLanguageInputField';
import {
  getLanguageRtlStatus,
  getLangFullName,
  setMultiLangArrayFromData,
  displayMultiLangLabelIfPresent,
  setMultiLangArrayFromDataForOptions,
  checkIfLanguageIsPresentInMultiLang,
  checkIfAnyLanguageIsPresentInMultiLang,
  compareObjects
} from 'utils/multiLangUtils';
import { useTranslation } from 'react-i18next';
import EmojiModal from './EmojiModal';
import emojies, { DARK_EMOJI, LIGHT_EMOJI } from 'assets/emojies';
import CombinationModal from './CombinationModal';
import { useDispatch } from 'react-redux';
import { GET_ALL_QUESTION_DISEASEID } from 'store/redux/actions/action.constants';

export default function MapTree() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState([]);
  const [show, setShow] = useState();
  const [activeStep, setActiveStep] = useState(0);

  const [question, setQuestion] = useState('');
  const [multiLangQuestion, setMultiLangQuestion] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');

  const [options, setOptions] = useState([]);
  const [isPainScoreQuestion, setIsPainScoreQuestion] = useState(false);
  const [showEmojiesModal, setShowEmojiesModal] = useState(false);
  const [emoji, setEmoji] = useState({ light: '', dark: '', lightURL: '', darkURL: '' });

  const [optionText, setOptionText] = useState('');
  const [multiLangOptionText, setMultiLangOptionText] = useState([]);

  const [p, setP] = useState('a');
  const [questionType, setQuestionType] = useState('');
  const [enableSearch, setEnableSearch] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [info, setInfo] = useState('');
  const [multiLangInfo, setMultiLangInfo] = useState([]);

  const [tabQuestion, setTabQuestion] = useState('');
  const [multiLangTabQuestion, setMultiLangTabQuestion] = useState([]);

  const [placement, setPlacement] = useState([]);
  const [applyCondition, setApplyCondition] = useState(false);
  const [applyAge, setApplyAge] = useState(false);
  const [isInfant, setIsInfant] = useState(false);
  const [applyGender, setApplyGender] = useState(false);
  const [ageRange, setAgeRange] = useState([0, 120]);
  const [conditionalGenders, setConditionalGenders] = useState([]);
  const [skip, setSkip] = useState(false);
  const [selectedNode, setSelectedNode] = useState();
  const [delModal, setDelModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [forceDel, setForceDel] = useState(false);
  const [showToolbar, setShowToolbar] = useState(false);
  const [preQuestionId, setPreQuestionId] = useState('');
  const [preQuestionType, setPreQuestionType] = useState('');
  const [skipQuestion, setSkipQuestion] = useState(true);
  const [noSkipQuestion, setNoSkipQuestion] = useState(true);
  const [conditionalQuestion, setConditionalQuestion] = useState();

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [showEditOptionModal, setshowEditOptionModal] = useState(false);
  const [multiLangOptionTextForEdit, setMultiLangOptionTextForEdit] = useState([]);
  const [pForEdit, setPEdit] = useState('a');
  const [optionBeingEdited, setOptionBiengEdited] = useState(null);

  const langInUse = localStorage.getItem('i18nextLng');
  const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));

  // Combination
  const [showCombinationModal, setShowCombinationModal] = useState(false);

  const { state } = useLocation();
  const nodeTypes = {
    option: OptionNode,
    question: QuestionNode,
    deadOption: OptionDeadNode
  };

  const onNodesChange = useCallback((changes) => {
    if (changes.length) {
      if (changes[0]?.type !== 'remove') {
        setNodes((nds) => applyNodeChanges(changes, nds));
      }
    }
  }, []);
  // const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  const setNodesAndEdges = () => {
    const _nodes = [...nodes];
    const _edges = [...edges];

    var val = 1;
    questions.forEach((e, qIndex) => {
      _nodes.push({
        id: e._id,
        data: {
          label: (
            <div className="text-updater-node-question">
              {' '}
              {displayMultiLangLabelIfPresent(e.question, langInUse, e.multiLanguageQuestion)}
            </div>
          ),
          toolbarPosition: Position.Top,
          toolbarVisible: true,
          props: { ...e }
        },
        position: { x: e.questionType !== 'input' ? (e.options.length - 1) * 125 : 250 * val, y: qIndex * 300 },
        type: 'question'
      });

      if (e.questionType !== 'input') {
        let _options = [...e.options];
        _options.forEach((f, oIndex) => {
          val = oIndex;
          _nodes.push({
            id: f._id,
            data: {
              label: (
                <div className={getOptionClassName(f)}>
                  {displayMultiLangLabelIfPresent(f.option, langInUse, f.multiLanguageOption) +
                    ' (' +
                    f.code +
                    ') ' +
                    (f?.conditionalCode ? ' - {' + f?.conditionalCode + '}' : '')}
                </div>
              ),
              toolbarPosition: Position.Top,
              toolbarVisible: true,
              props: { ...f },
              question: { ...e }
            },
            position: { x: oIndex * 250, y: (qIndex + 1) * 300 - 150 },
            type: f?.nextQuestion ? 'option' : 'deadOption'
          });

          _edges.push({
            source: e._id,
            target: f._id,
            animated: true,
            style: { strokeWidth: 1, stroke: '#004561' }
          });

          if (f?.nextQuestion) {
            _edges.push({
              source: f._id,
              target: f?.nextQuestion?._id,
              animated: true,
              style: { strokeWidth: 1, stroke: '#004561' }
            });
          }
        });
      } else {
        _edges.push({
          source: e._id,
          target: e?.nextQuestion?._id,
          animated: true,
          style: { strokeWidth: 1, stroke: '#004561' }
        });
      }
    });
    setNodes(_nodes);
    setEdges(_edges);
  };

  useEffect(() => {
    setNodesAndEdges();
  }, [questions]);

  useEffect(() => {
    populatePath();
  }, []);

  useEffect(() => {
    if (forceDel) {
      handleSubmit(true);
    }
  }, [forceDel]);

  //option color is changing here
  const getOptionClassName = (obj) => {
    if (obj.nextQuestion) {
      if (obj?.conditionalCode || obj?.condition?.applyAge || obj?.condition?.applyGender) {
        return 'conditional-option-node';
      } else {
        return 'option-node';
      }
    } else {
      if (obj?.conditionalCode || obj?.condition?.applyAge || obj?.condition?.applyGender) {
        return 'conditional-option-node';
      } else {
        return 'dead-option-node';
      }
    }
  };

  const resetStates = () => {
    setQuestion('');
    setSelectedQuestionId('');
    setQuestionType('');
    setOptions([]);
    setOptionText('');
    setActiveStep(0);
    setIsPainScoreQuestion(false);
    setEmoji({ light: '', dark: '', lightURL: '', darkURL: '' });
    setP('a');
    setShowInfo(false);
    setEnableSearch(false);
    setPlacement([]);
    setApplyCondition(false);
    setApplyAge(false);
    setApplyGender(false);
    setAgeRange([0, 10]);
    setConditionalGenders([]);
    setMultiLangInfo([]);
    setMultiLangQuestion([]);
    setMultiLangTabQuestion([]);
    resetMultiLangOptionText();
    resetMultiLangOptionTextForEdit();
    setOptionBiengEdited(null);
  };

  const deleteOptionProps = (id) => {
    let _nodes = [...nodes].filter((e) => e.id !== id);
    setNodes(_nodes);
    let _edges = [...edges].filter((e) => e.target !== id);
    setEdges(_edges);
  };

  const deleteDetachEdgeProps = (id) => {
    let _edges = [...edges].filter((e) => e.source !== id);
    setEdges(_edges);
  };

  const deleteDetachQuestionEdgeProps = (id) => {
    let _edges = [...edges].filter((e) => e.target !== id);
    setEdges(_edges);
  };

  const deleteQuestionProps = (id) => {
    let _nodes = [...nodes].filter((e) => e.id !== id);
    let __nodes = [..._nodes].filter((e) => e?.data?.question?._id !== id);
    setNodes(__nodes);
    let _edges = [...edges].filter((e) => e.target !== id && e.source !== id);
    setEdges(_edges);
  };

  const createOption = () => {
    editOption();
  };

  const editOption = () => {
    console.log('CALLED OPTION');
    if (selectedNode?.data?.props?.questionType !== 'input') {
      setActiveStep(2);
    }
    setIsEditing(true);
    setShow(true);

    let qu = selectedNode?.data?.question || selectedNode?.data?.props;
    let qType = selectedNode?.type;
    let qData = selectedNode?.data;

    qu.options = formatOptionsForMultiLanguage(qu.options);
    //console.log('OPTIONS FORMATTED => ', qu.options);

    setQuestion(qu.question);
    setSelectedQuestionId(qData.props?._id);
    setMultiLangQuestion(setMultiLangArrayFromData(qu.question, setQuestion, 'en', qu.multiLanguageQuestion));
    setEnableSearch(qu.enableSearch);

    setQuestionType(qu.questionType);
    if (qu.questionType !== 'input') {
      setOptions(qu.options);
    }
    setIsPainScoreQuestion(qu?.isPainScoreQuestion);

    setShowInfo(qu.isInfoButtonEnable);
    if (qu.isInfoButtonEnable) {
      setInfo(qu.info);
      setMultiLangInfo(setMultiLangArrayFromData(qu.info, setInfo, 'en', qu.multiLanguageInfo));
      setTabQuestion(qu.tabQuestion);
      setMultiLangTabQuestion(setMultiLangArrayFromData(qu.tabQuestion, setTabQuestion, 'en', qu.multiLanguageTabQuestion));
    }

    if (qu.placement.length > 0) {
      setPlacement(qu.placement);

      //setting condition
      let _condition;
      for (let index = 0; index < qu.placement.length; index++) {
        let pl = qu.placement[index];
        if (pl?.preQuestion) {
          if (pl.preQuestion.questionType === 'input' || pl.preQuestion.questionType === 'multiOption') {
            _condition = pl.preQuestion?.condition;
          } else {
            pl.preQuestion.options.forEach((e) => {
              if (e.nextQuestion === qu._id) {
                _condition = e?.condition;
              }
            });
          }
        }
      }

      if (_condition?.applyGender) {
        setApplyGender(true);

        let _genders = [];
        if (!_condition.conditionalGenders.includes('Male')) _genders.push('Male');
        if (!_condition.conditionalGenders.includes('Female')) _genders.push('Female');
        // if (!_condition.conditionalGenders.includes('Other')) _genders.push('Other');

        setConditionalGenders(_genders);
        setApplyCondition(true);
      }
      if (_condition?.applyAge) {
        setApplyAge(true);
        setAgeRange(_condition.ageRange);
        setApplyCondition(true);
      }
    } else {
      if (qType === 'option' || qType === 'multiOption') {
        let _condition = qData.question.conditionObject;
        if (_condition?.applyGender) {
          setApplyGender(true);

          let _genders = [];
          if (!_condition.conditionalGenders.includes('Male')) _genders.push('Male');
          if (!_condition.conditionalGenders.includes('Female')) _genders.push('Female');

          setConditionalGenders(_genders);
          setApplyCondition(true);
        }
        if (_condition?.applyAge) {
          setApplyAge(true);
          setAgeRange(_condition.ageRange);
          setApplyCondition(true);
        }
        if (_condition?.conditionalQuestion) {
          setNoSkipQuestion(false);
          setConditionalQuestion(_condition?.conditionalQuestion);
        }
      }
    }
    console.log('ALL OPTIONS => ', qu.options);
    setSkipQuestion(true);
    qu.options.forEach((opt) => {
      if (opt.conditionalCode) {
        console.log('CONDITIONAL CODE FOUND FOR OPT => ', opt);
        setSkipQuestion(false);
        return false;
      }
    });
  };

  const createQuestion = (node, event) => {
    setPreQuestionId(node.data.props._id);
    setPreQuestionType(node.data.props.questionType);
    resetStates();
    setIsEditing(false);
    setShow(true);

    if (event === 'opt') {
      setPlacement([
        {
          preQuestion: node?.data?.question?._id,
          options_ids: [node?.data?.props._id],
          isPreQuestionHaveOptions: node?.data?.question?.questionType !== 'input' ? true : false
        }
      ]);
    } else {
      setPlacement([
        {
          preQuestion: node.data.props._id,
          options_ids: [],
          isPreQuestionHaveOptions: node?.data?.props?.questionType !== 'input' ? true : false
        }
      ]);
    }
  };

  const editQuestion = () => {
    console.log('CALLED QUESTIONS');

    setIsEditing(true);
    setShow(true);

    let qu = selectedNode?.data?.props;
    console.log('OPTIONS IN START => ', qu.options);

    qu.options = formatOptionsForMultiLanguage(qu.options);

    setQuestion(qu.question);
    setSelectedQuestionId(qu._id);
    setMultiLangQuestion(setMultiLangArrayFromData(qu.question, setQuestion, 'en', qu.multiLanguageQuestion));
    setEnableSearch(qu.enableSearch);

    setQuestionType(qu.questionType);
    if (qu.questionType !== 'input') {
      setOptions(qu.options);
    }
    setIsPainScoreQuestion(qu?.isPainScoreQuestion);

    setShowInfo(qu.isInfoButtonEnable);
    if (qu.isInfoButtonEnable) {
      setInfo(qu.info);
      setMultiLangInfo(setMultiLangArrayFromData(qu.info, setInfo, 'en', qu.multiLanguageInfo));
      setTabQuestion(qu.tabQuestion);
      setMultiLangTabQuestion(setMultiLangArrayFromData(qu.tabQuestion, setTabQuestion, 'en', qu.multiLanguageTabQuestion));
    }

    if (qu.placement.length > 0) {
      setPlacement(qu.placement);

      //setting condition
      let _condition;
      for (let index = 0; index < qu.placement.length; index++) {
        let pl = qu.placement[index];
        if (pl.preQuestion?.questionType === 'input') {
          _condition = pl.preQuestion?.condition;
        } else {
          pl.preQuestion?.options.forEach((e) => {
            if (e.nextQuestion === qu._id) {
              _condition = e?.condition;
            }
          });
        }
      }

      if (_condition?.applyGender) {
        setApplyGender(true);

        let _genders = [];
        if (!_condition.conditionalGenders.includes('Male')) _genders.push('Male');
        if (!_condition.conditionalGenders.includes('Female')) _genders.push('Female');
        // if (!_condition.conditionalGenders.includes('Other')) _genders.push('Other');

        setConditionalGenders(_genders);
        setApplyCondition(true);
      }
      if (_condition?.applyAge) {
        setApplyAge(true);
        setAgeRange(_condition.ageRange);
        setApplyCondition(true);
      }
      if (_condition?.conditionalQuestion) {
        setNoSkipQuestion(false);
        setConditionalQuestion(_condition?.conditionalQuestion);
      }
    }

    qu.options.forEach((opt) => {
      if (opt.conditionalCode) {
        setSkipQuestion(false);
        return false;
      }
    });
  };

  const handleAge = (event, newValue) => {
    setAgeRange(newValue);
  };

  const populatePath = async () => {
    try {
      const response = await getQuestionByDisease({
        diseaseID: state?.diseaseId,
        version: state?.version
      });
      if (response?.status) {
        const payload = response.data.filter((e) => !e.isDeleted);
        setQuestions(payload);
        dispatch({
          type: GET_ALL_QUESTION_DISEASEID,
          payload
        });
      } else {
        makeToast('error', 'Unable to fetch questions.');
      }
    } catch (error) {
      makeToast('error', 'Unable to fetch questions.');
    }
  };

  const handleClick = (e) => {
    //console.log('OPTIONS ARRAY => ', options);
    //console.log('OPTION TO DELETE new=>  ', e);
    let newOptions = options.filter((event) => !compareObjects(event, e));
    //console.log('OPTIONS NEW => ', newOptions);
    setOptions(newOptions);
  };

  const handleEditOption = (e) => {
    setOptionBiengEdited(e);
    const optionFoundFromArray = options.find((opt) => opt._id === e._id);
    setPEdit(e.code);

    if (e.isPainScoreQuestion) {
      setEmoji((p) => ({
        ...p,
        light: e.painScoreDetails?.selectedEmojiForDarkMode,
        dark: e.painScoreDetails?.selectedEmojiForLightMode,
        lightURL: e.painScoreDetails?.emojiUrlForLightMode,
        darkURL: e.painScoreDetails?.emojiUrlForDarkMode
      }));
    }

    const arrayToSet = [];
    multiLangQuestion.forEach((quest) => {
      let contentToAdd = '';
      const objFound = e.multiLanguageOption.find((obj) => obj.language === quest.language);
      if (objFound) {
        contentToAdd = objFound.content;
      } else {
        contentToAdd = '';
      }
      const obj = { language: quest.language, content: contentToAdd };
      arrayToSet.push(obj);
    });
    setMultiLangOptionTextForEdit(arrayToSet);
    setshowEditOptionModal(true);
  };

  const handleEditOptionSubmit = (emoji) => {
    let isEmpty = true;

    multiLangOptionTextForEdit.forEach((opt) => {
      if (opt.content != '') {
        isEmpty = false;
      }
    });

    if (pForEdit === 'a') {
      return makeToast('error', t('Select P value !'));
    }
    if (isEmpty) {
      return makeToast('error', t('Options cant be empty !'));
    }

    const _newArray = [...options];
    const optionFoundFromArray = _newArray.find((opt) => opt._id === optionBeingEdited._id);
    optionFoundFromArray.multiLanguageOption = multiLangOptionTextForEdit;
    optionFoundFromArray.code = pForEdit;
    if (isPainScoreQuestion) {
      const painScoreDetails = {
        selectedEmojiForLightMode: emoji.light,
        selectedEmojiForDarkMode: emoji.dark,
        emojiUrlForLightMode: '',
        emojiUrlForDarkMode: ''
      };
      optionFoundFromArray.painScoreDetails = painScoreDetails;
    }
    const index = _newArray.findIndex((opt) => opt._id === optionBeingEdited._id);
    _newArray[index] = optionFoundFromArray;
    setOptions(_newArray);
    setOptionBiengEdited(null);
    setshowEditOptionModal(false);
  };
  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (!checkIfLanguageIsPresentInMultiLang(langInUse, multiLangQuestion)) makeToast('error', 'Question is missing.');
        else setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;

      case 1:
        if (!questionType) makeToast('error', 'Question Type is missing.');
        else if (questionType === 'input') setActiveStep((prevActiveStep) => prevActiveStep + 2);
        else setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;

      case 2:
        if (options.length === 0 && questionType !== 'input') makeToast('error', 'Options are missing.');
        else if (options.length < 2 && questionType !== 'input') makeToast('error', 'Options must be more than 1.');
        else {
          setActiveStep((prevActiveStep) => prevActiveStep + (skip ? 3 : 1));
          if (skip) setSkip(false);
        }
        break;

      case 3:
        if (showInfo && !checkIfLanguageIsPresentInMultiLang(langInUse, multiLangInfo)) makeToast('error', 'Info is missing.');
        else if (showInfo && !checkIfLanguageIsPresentInMultiLang(langInUse, multiLangTabQuestion))
          makeToast('error', 'Question is missing for Android Tablet + iPad.');
        else if (questionType === 'input') setActiveStep((prevActiveStep) => prevActiveStep + 2);
        else setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;

      case 4:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;

      case 5:
        if (applyCondition && !applyAge && !applyGender) {
          makeToast('error', 'Please select a condition factor.');
          break;
        } else if (applyCondition && applyGender && conditionalGenders.length === 0) {
          makeToast('error', 'Select a gender for condition.');
          break;
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        }

      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
    }
  };

  const handleBack = () => {
    if (activeStep === 3 && questionType === 'input') setActiveStep((prevActiveStep) => prevActiveStep - 2);
    else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (bit) => {
    setNodes([]);
    setEdges([]);
    var dataToSend = {
      diseaseID: state?.diseaseId,
      questionType,
      question,
      multiLanguageQuestion: multiLangQuestion,
      version: state?.version,
      placement: placement,
      preQuestion: placement?.map((e) => {
        return {
          question: e.preQuestion,
          answer: e.options_ids
        };
      })
    };

    if (questionType === 'singleOption' || questionType === 'multiOption') {
      dataToSend = {
        ...dataToSend,
        enableSearch,
        options,
        isPainScoreQuestion
      };
    } else {
      dataToSend = {
        ...dataToSend,
        enableSearch,
        options: []
      };
    }
    if (preQuestionType === 'input') {
      dataToSend = {
        ...dataToSend,
        preQuestion: preQuestionId,
        preQuestionType: preQuestionType
      };
    }

    // (showInfo && (info !== '' || tabQuestion !== ''))
    if (
      showInfo &&
      (checkIfAnyLanguageIsPresentInMultiLang(multiLangInfo) || checkIfAnyLanguageIsPresentInMultiLang(multiLangTabQuestion))
    ) {
      dataToSend = {
        ...dataToSend,
        isInfoButtonEnable: showInfo,
        info,
        multiLanguageInfo: multiLangInfo,
        tabQuestion,
        multiLanguageTabQuestion: multiLangTabQuestion
      };
    } else {
      dataToSend = {
        ...dataToSend,
        isInfoButtonEnable: false,
        info,
        multiLanguageInfo: [],
        tabQuestion,
        multiLanguageTabQuestion: []
      };
    }

    if (applyCondition) {
      let condition = {
        applyAge,
        applyGender
      };

      if (applyGender) {
        let _genders = [];
        if (!conditionalGenders.includes('Male')) _genders.push('Male');
        if (!conditionalGenders.includes('Female')) _genders.push('Female');
        // if (!conditionalGenders.includes('Other')) _genders.push('Other');
        condition = {
          ...condition,
          conditionalGenders: _genders
        };
      }

      if (applyAge) {
        condition = {
          ...condition,
          ageRange
        };
      }

      if (skipQuestion) {
        condition = {
          ...condition,
          skipQuestion
        };
        if (!noSkipQuestion) {
          condition = {
            ...condition,
            conditionalQuestion
          };
        }
      }

      dataToSend = {
        ...dataToSend,
        condition
      };
    }

    console.log('dataToSend', dataToSend);

    if (state?.data || isEditing === true || bit === true) {
      dataToSend = {
        ...dataToSend,
        id: state?.data?._id || selectedNode?.data?.question?._id || selectedNode?.data?.props?._id
      };
      let response = await updateQuestions(dataToSend);
      console.log({ response });
      if (response && response?.status) {
        await populatePath();
        makeToast('success', 'Question updated successfully!');
      } else {
        makeToast('error', response?.message || 'Error');
      }
      setShow(false);
      setForceDel(false);
      setActiveStep();
      resetStates();
    } else {
      console.log({ dataToSend });
      let response = await createQuestions({ ...dataToSend, isParentQuestion: questions.length === 0 ? true : false });
      console.log({ response });
      if (response && response?.status) {
        populatePath();
        makeToast('success', 'Question added successfully!');
        resetStates();
      } else {
        makeToast('error', response?.message || 'Error');
      }
      setShow(false);
      setForceDel(false);
      setActiveStep();
      resetStates();
    }
  };

  const checkDisabledButton = () => {
    let disable;
    if (
      !checkIfLanguageIsPresentInMultiLang(langInUse, multiLangQuestion) ||
      !questionType ||
      (questionType !== 'input' && options?.length < 2) ||
      (showInfo &&
        (!checkIfLanguageIsPresentInMultiLang(langInUse, multiLangInfo) ||
          !checkIfLanguageIsPresentInMultiLang(langInUse, multiLangTabQuestion))) ||
      (applyCondition && !applyAge && !applyGender) ||
      (applyCondition && applyGender && conditionalGenders?.length === 0) ||
      (applyAge && ageRange[0] === ageRange[1]) ||
      (skipQuestion && !noSkipQuestion && !conditionalQuestion) ||
      state?.version === '' ||
      state?.version === 'a'
    ) {
      disable = true;
    } else {
      disable = false;
    }

    if (isPainScoreQuestion) {
      disable = !options.every((o) => {
        if (o.painScoreDetails) {
          const { selectedEmojiForDarkMode, selectedEmojiForLightMode, emojiUrlForLightMode, emojiUrlForDarkMode } = o.painScoreDetails;
          return !(selectedEmojiForLightMode && emojiUrlForLightMode) || !(selectedEmojiForDarkMode && emojiUrlForDarkMode);
        }
      });
    }

    return disable;
  };

  const getGenderText = () => {
    if (applyGender && conditionalGenders?.length > 0) {
      let _genders = [];
      if (!conditionalGenders.includes('Male')) _genders.push(t('Male'));
      if (!conditionalGenders.includes('Female')) _genders.push(t('Female'));
      if (_genders.length) {
        return '(' + _genders.join(', ') + ')';
      }
    }
    return '';
  };

  const handleMultiLangOptionText = (lang, content) => {
    console.log('LANG => ' + lang + ' CONTENT => ' + content);
    // if (!lang && !content) {
    //   let arrayToSet = [];
    //   multiLangQuestion.map((quest) => {
    //     let obj = { language: quest.language, content: '' };
    //     arrayToSet.push(obj);
    //     console.log("OPTION ARR => ",arrayToSet);
    //     return arrayToSet;
    //   });
    // } else {
    //   let arrayToSet = multiLangOptionText;
    //   arrayToSet.forEach((arr) => {
    //     if (arr.language === lang) {
    //       arr.content = content;
    //     }
    //   });
    //   console.log('OPTION ARR => ', arrayToSet);
    //   setMultiLangOptionText(arrayToSet)
    // }
    let arrayToSet = [...multiLangOptionText];
    console.log('ARRAY = ', arrayToSet);
    if (arrayToSet.length === 0) {
      multiLangQuestion.forEach((quest) => {
        let obj = { language: quest.language, content: '' };
        arrayToSet.push(obj);
        //console.log('OPTION ARR EMPTY => ', arrayToSet);
      });
    }
    arrayToSet.forEach((arr) => {
      if (arr.language === lang) {
        if (lang === 'en') {
          setOptionText(content);
        }
        arr.content = content;
      }
    });
    console.log('OPTION ARR => ', arrayToSet);
    setMultiLangOptionText(arrayToSet);
  };

  const getMultiLangOptionValue = (lang) => {
    let objFound = multiLangOptionText.find((obj) => obj.language === lang);
    if (objFound) {
      return objFound;
    } else {
      return '';
    }
  };

  const checkIfOptionsAreEmpty = () => {
    if (isPainScoreQuestion && (!emoji.light || !emoji.dark)) {
      return false;
    }
    let isEmpty = false;

    multiLangOptionText.forEach((obj) => {
      if (obj.content === '') {
        isEmpty = true;
      }
    });

    return isEmpty;
  };

  const resetMultiLangOptionText = () => {
    console.log('RESET OPTIONS CALLED');
    let arrayToSet = [];
    multiLangQuestion.forEach((quest) => {
      let obj = { language: quest.language, content: '' };
      arrayToSet.push(obj);
      //console.log('OPTION ARR EMPTY => ', arrayToSet);
    });
    setMultiLangOptionText(arrayToSet);
  };

  const resetMultiLangOptionTextForEdit = () => {
    let arrayToSet = [];
    multiLangQuestion.forEach((quest) => {
      let obj = { language: quest.language, content: '' };
      arrayToSet.push(obj);
      //console.log('OPTION ARR EMPTY => ', arrayToSet);
    });
    setMultiLangOptionTextForEdit(arrayToSet);
  };

  const formatOptionsForMultiLanguage = (_options) => {
    const optArray = [..._options];
    optArray.forEach((opt) => {
      console.log('OPT => ', opt);
      const arrMultiLang = setMultiLangArrayFromDataForOptions(opt.option, 'en', opt.multiLanguageOption);
      opt.multiLanguageOption = arrMultiLang;
    });
    return optArray;
  };
  const getContentByIndex = (index) => {
    if (index === 0) {
      return (
        <div className="form-group mt-4">
          {/* <p className="headings">Question</p>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            maxLength={500}
            className="textarea-styles"
            placeholder="Ask a Question"
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
          /> */}
          <MultiLanguageInputField
            title={t('Question')}
            baseValue={question}
            setBaseValue={setQuestion}
            basePlaceHolder={t('Ask_a_Question')}
            multiLangArray={multiLangQuestion}
            setmultiLangArray={setMultiLangQuestion}
            isTextArea
            isForQuestions
            defaultLanguage={langInUse}
          />
        </div>
      );
    } else if (index === 1) {
      return (
        <>
          <div className="form-group">
            <p className="headings">{t('Question_Types')}</p>
            <FormControl>
              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                <FormControlLabel
                  value="input"
                  control={<Radio />}
                  label={t('Input')}
                  checked={questionType === 'input'}
                  onClick={() => setQuestionType('input')}
                />
                <FormControlLabel
                  value="singleOption"
                  control={<Radio />}
                  label={t('Single_Select')}
                  checked={questionType === 'singleOption'}
                  onClick={() => setQuestionType('singleOption')}
                />
                <FormControlLabel
                  value="multiOption"
                  control={<Radio />}
                  label={t('Multi_Select')}
                  checked={questionType === 'multiOption'}
                  onClick={() => setQuestionType('multiOption')}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {questionType === 'singleOption' && (
            <FormControlLabel
              control={<Checkbox />}
              checked={isPainScoreQuestion}
              onChange={(e) => setIsPainScoreQuestion(e.target.checked)}
              label={t('Painscore')}
            />
          )}
        </>
      );
    } else if (index === 2) {
      if (questionType === 'singleOption' || questionType === 'multiOption') {
        return (
          <div className="mt-4">
            <div className="d-flex align-items-center justify-content-between headings p-2">
              <div className="me-3 ms-2 bold font-16">{t('Options')}</div>
              <button
                className={`${!checkIfOptionsAreEmpty() || p !== 'a' ? 'blue-add-question-btn-active' : 'blue-add-question-btn-inactive'}`}
                onClick={() => {
                  if (checkIfOptionsAreEmpty()) {
                    makeToast('error', 'Write all Options ');
                  } else if (p === 'a') {
                    makeToast('error', 'Select Value of P');
                  } else if (isPainScoreQuestion && ((!emoji.light && !emoji.lightURL) || (!emoji.dark && !emoji.darkURL))) {
                    makeToast('error', 'Select Emoji for dark & light');
                  } else {
                    let _options = [...options];
                    const newOption = {
                      option: optionText,
                      multiLanguageOption: multiLangOptionText,
                      code: p
                    };
                    if (isPainScoreQuestion) {
                      newOption.painScoreDetails = {
                        isSelectedEmojiForLightMode: emoji.light ? true : false,
                        selectedEmojiForLightMode: emoji.light,
                        emojiUrlForLightMode: emoji.lightURL,
                        isSelectedEmojiForDarkMode: emoji.dark ? true : false,
                        selectedEmojiForDarkMode: emoji.dark,
                        emojiUrlForDarkMode: emoji.darkURL
                      };
                      setEmoji({ light: '', dark: '', lightURL: '', darkURL: '' });
                    }
                    _options.push(newOption);
                    setOptions(_options);
                    setOptionText('');
                    resetMultiLangOptionText();
                    resetMultiLangOptionTextForEdit();
                    setP('a');
                  }
                }}
              >
                {t('Add_Option')}
              </button>
            </div>

            {showEmojiesModal && (
              <EmojiModal showModal={showEmojiesModal} setShowModal={setShowEmojiesModal} emoji={emoji} setEmoji={setEmoji} />
            )}

            <div className="d-flex py-3 justify-content-between align-items-center">
              <div className="col-7">
                {multiLangQuestion.map((quest, i) => {
                  return (
                    <Form.Control
                      key={i}
                      maxLength={100}
                      className="custom-input"
                      value={getMultiLangOptionValue(quest.language)?.content || ''}
                      onChange={(e) => handleMultiLangOptionText(quest.language, e.target.value)}
                      placeholder={getLangFullName(quest.language)}
                      style={{ textAlign: getLanguageRtlStatus(quest.language) }}
                    />
                  );
                })}
              </div>

              <Select
                placeholder="aaa"
                value={p}
                size="small"
                onChange={(e) => {
                  if (e.target.value !== 'a') {
                    setP(e.target.value);
                  }
                }}
              >
                <MenuItem defaultChecked value="a">
                  <em>{t('Select_P')}</em>
                </MenuItem>
                {['P1', 'P2', 'P3', 'P4', 'P5'].map((ev) => (
                  <MenuItem key={ev} value={ev}>
                    {ev}
                  </MenuItem>
                ))}
              </Select>
              {isPainScoreQuestion && (
                <Stack direction="row" alignItems="center">
                  <Button size="small" onClick={() => setShowEmojiesModal('light')} className="flex-column">
                    <img
                      src={LIGHT_EMOJI[emoji?.light] || emoji.lightURL || emojies.placeholder}
                      alt="emoji"
                      width={24}
                      height={24}
                      className="rounded-circle"
                    />
                    <Typography variant="caption">Light</Typography>
                  </Button>
                  <Button size="small" color="secondary" onClick={() => setShowEmojiesModal('dark')} className="flex-column">
                    <img
                      src={DARK_EMOJI[emoji?.dark] || emoji.darkURL || emojies.placeholder}
                      alt="emoji"
                      width={24}
                      height={24}
                      className="rounded-circle"
                    />
                    <Typography variant="caption">Dark</Typography>
                  </Button>
                </Stack>
              )}
            </div>

            {options?.length > 0 && (
              <CustomOptionsTable
                setData={setOptions}
                show={applyCondition && !skipQuestion && ((applyGender && conditionalGenders.length > 0) || applyAge)}
                handleClick={handleClick}
                handleEdit={handleEditOption}
                data={options}
                showEditOptionModal={showEditOptionModal}
                setshowEditOptionModal={setshowEditOptionModal}
                multiLangOptionTextForEdit={multiLangOptionTextForEdit}
                setMultiLangOptionTextForEdit={setMultiLangOptionTextForEdit}
                setOptionText={setOptionText}
                optionBeingEdited={optionBeingEdited}
                setOptionBiengEdited={setOptionBiengEdited}
                handleEditOptionSubmit={handleEditOptionSubmit}
                pForEdit={pForEdit}
                setPEdit={setPEdit}
                isPainScoreQuestion={isPainScoreQuestion}
              />
            )}
          </div>
        );
      }
    } else if (index === 3) {
      return (
        <>
          <div className="form-group mt-4">
            <p className="headings">{t('Show_Info')}</p>
            <FormControl>
              <RadioGroup row>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t('Yes')}
                  checked={showInfo === true}
                  onClick={() => setShowInfo(true)}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('No')}
                  checked={showInfo === false}
                  onClick={() => setShowInfo(false)}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {showInfo && (
            <>
              <div className="form-group">
                {/* <p className="headings">Info</p>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  maxLength={100}
                  placeholder="Write information"
                  className="textarea-styles"
                  onChange={(e) => setInfo(e.target.value)}
                  value={info}
                /> */}
                <MultiLanguageInputField
                  title={'Info'}
                  baseValue={info}
                  setBaseValue={setInfo}
                  basePlaceHolder={'Write information'}
                  multiLangArray={multiLangInfo}
                  setmultiLangArray={setMultiLangInfo}
                  isTextArea
                  isForQuestions
                  defaultLanguage={langInUse}
                />
              </div>
              <div className="form-group">
                {/* <p className="headings">Question for Tablet & iPad</p>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  maxLength={500}
                  placeholder="Write information"
                  className="textarea-styles"
                  onChange={(e) => setTabQuestion(e.target.value)}
                  value={tabQuestion}
                /> */}
                <MultiLanguageInputField
                  title={'Question for Tablet & iPad'}
                  baseValue={tabQuestion}
                  setBaseValue={setTabQuestion}
                  basePlaceHolder={'Write information'}
                  multiLangArray={multiLangTabQuestion}
                  setmultiLangArray={setMultiLangTabQuestion}
                  isTextArea
                  isForQuestions
                  defaultLanguage={langInUse}
                />
              </div>
            </>
          )}
        </>
      );
    } else if (index === 4) {
      return (
        <div className="form-group">
          <p className="headings">{t('Enable_Search')}</p>
          <FormControl>
            <RadioGroup row>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={t('Yes')}
                checked={enableSearch === true}
                onClick={() => setEnableSearch(true)}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t('No')}
                checked={enableSearch === false}
                onClick={() => setEnableSearch(false)}
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    } else if (index === 5) {
      return (
        <div className="form-group">
          <p className="headings">{t('Apply_any_Conditions?')}</p>
          <FormControl>
            <RadioGroup row>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={t('Yes')}
                checked={applyCondition === true}
                onClick={() => setApplyCondition(true)}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t('No')}
                checked={applyCondition === false}
                onClick={() => {
                  setApplyCondition(false);
                  setApplyAge(false);
                  setApplyGender(false);
                  setAgeRange([0, 10]);
                  setConditionalGenders([]);
                  let _options = [...options].map((object) => {
                    delete object['conditionalCode'];
                    return object;
                  });
                  setOptions(_options);
                }}
              />
            </RadioGroup>
          </FormControl>

          {applyCondition && (
            <div className="d-flex flex-column">
              <p className="headings">{t('Condition_Factors')}</p>

              <FormControlLabel
                control={<Checkbox />}
                checked={applyAge}
                onChange={(event) => {
                  setApplyAge(event.target.checked);
                  setAgeRange([0, 10]);
                }}
                label={t('Age_Group')}
              />

              {applyAge && (
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isInfant}
                  onChange={(event) => {
                    setIsInfant(event.target.checked);
                    if (event.target.checked) {
                      setAgeRange([0, 4]);
                    } else {
                      setAgeRange([0, 10]);
                    }
                  }}
                  label={t('For Infants')}
                />
              )}
              {applyAge &&
                (isInfant ? (
                  <div className="mx-3" style={{ width: 200 }}>
                    <p className="my-3">{ageRange[0] + ' - ' + ageRange[1] + ' ' + 'months old'}</p>
                    <Slider
                      getAriaLabel={() => 'Temperature range'}
                      value={ageRange}
                      onChange={handleAge}
                      valueLabelDisplay="auto"
                      min={0}
                      max={11}
                    />
                  </div>
                ) : (
                  <div className="mx-3" style={{ width: 200 }}>
                    <p className="my-3">{ageRange[0] + ' - ' + ageRange[1] + ' ' + t('years_old')}</p>
                    <Slider
                      getAriaLabel={() => 'Temperature range'}
                      value={ageRange}
                      onChange={handleAge}
                      valueLabelDisplay="auto"
                      max={120}
                    />
                  </div>
                ))}
              <FormControlLabel
                control={<Checkbox />}
                checked={applyGender}
                onChange={(event) => {
                  setApplyGender(event.target.checked);
                  setConditionalGenders([]);
                }}
                label={t('Gender')}
              />
              {applyGender && (
                <div className="m-3">
                  {[t('Male'), t('Female')].map((gen, i) => (
                    <FormControlLabel
                      key={i}
                      control={<Checkbox />}
                      checked={conditionalGenders.includes(gen)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          let _gen = [...conditionalGenders];
                          _gen.push(gen);
                          setConditionalGenders(_gen);
                        } else {
                          let _gen = [...conditionalGenders];
                          let n = _gen.map((e) => {
                            if (e === gen) {
                              return null;
                            } else {
                              return e;
                            }
                          });
                          setConditionalGenders(n.filter((e) => e !== null));
                        }
                      }}
                      label={gen}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {((applyGender && conditionalGenders.length) || applyAge) && (
            <>
              <p className="headings mt-3">{t('Action_to_Perform')}</p>
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={t('Change_P_values')}
                    checked={skipQuestion === false}
                    onClick={() => {
                      if (questionType === 'input') {
                        makeToast('error', 'Please change question type to Single Select or Multi Select to change P values');
                      } else {
                        setSkipQuestion(false);
                        setActiveStep(2);
                        let myElement = document.getElementById('modal-body');
                        myElement.scrollTop = 0;
                      }
                    }}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={`${t('Skip_Question')} ${getGenderText()}`}
                    checked={skipQuestion === true}
                    onClick={() => {
                      setSkipQuestion(true);
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}

          {skipQuestion && applyCondition && (applyAge || (applyGender && conditionalGenders.length > 0)) && (
            <>
              <p className="headings mt-3">{t('Conditional_Question')}</p>
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={t('Select_from_list_of_questions')}
                    checked={noSkipQuestion === false}
                    onClick={() => setNoSkipQuestion(false)}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={t("I_don't_have_a_next_question_yet")}
                    checked={noSkipQuestion === true}
                    onClick={() => setNoSkipQuestion(true)}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}

          {!noSkipQuestion && skipQuestion && applyCondition && (
            <>
              <p className="headings">{t('Select_Conditional_Question')}</p>
              <Select
                style={{ width: 400 }}
                value={conditionalQuestion}
                onChange={(e) => {
                  // if (e.target.value) {
                  setConditionalQuestion(e.target.value);
                  // }
                }}
              >
                <MenuItem defaultChecked value={null}>
                  <em>{t('Select_Question')}</em>
                </MenuItem>

                {/* {questions
                  .filter((e) => e.question !== question)
                  .map((q) => (
                    <MenuItem value={q?.questionNumber}>
                      {displayMultiLangLabelIfPresent(q?.question, langInUse, q?.multiLanguageQuestion)}
                    </MenuItem>
                  ))} */}
                {questions
                  .filter((e) => e._id !== selectedQuestionId)
                  .map((q, i) => (
                    <MenuItem key={i} value={q?.questionNumber}>
                      {displayMultiLangLabelIfPresent(q?.question, langInUse, q?.multiLanguageQuestion)}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {questions.length === 0 ? (
        userFromLocalStorage.userRole === 'ed_supper_admin' || userFromLocalStorage.userRole === 'ed_admin' ? null : (
          <div
            className="d-flex justify-content-center"
            onClick={() => {
              setShow(true);
              setIsEditing(false);
            }}
          >
            <p className="add-quest">+</p>
          </div>
        )
      ) : (
        <div className="map-tree-wrapper" style={{ height: '85vh' }}>
          {userFromLocalStorage.userRole === 'opto_supper_admin' && (
            <div className="d-flex flex-column gap-2 align-items-end">
              {/* <button
              className="btn rounded font-12 bold text-white p-1"
              style={{ backgroundColor: '#37afff' }}
              onClick={() => {
                setShowCombinationModal(true);
              }}
            >
              + Add Combination
            </button> */}
              <button
                className="btn rounded font-12 bold text-white p-1"
                style={{ backgroundColor: '#37afff' }}
                onClick={() => navigate('/combination', { state })}
              >
                Show Combinations
              </button>
              <button
                className="btn rounded font-12 bold text-white p-1"
                style={{ backgroundColor: '#37afff' }}
                onClick={() => navigate('/allergyAlert', { state })}
              >
                Show Allergy alerts
              </button>
            </div>
          )}
          <ReactFlow
            nodes={nodes}
            onNodesChange={onNodesChange}
            edges={edges}
            minZoom={0.1}
            maxZoom={10}
            // onEdgesChange={onEdgesChange}
            nodesDraggable={true}
            zoomOnScroll={true}
            panOnDrag={true}
            // nodeTypes={nodeTypes}
            onNodeClick={(_event, node) => {
              const userRec = JSON.parse(localStorage.getItem('user'));
              if (userRec.userRole === 'ed_supper_admin' || userRec.userRole === 'ed_admin') {
                //do nothing
              } else {
                setShowToolbar(true);
                setSelectedNode(node);
              }
              console.log({ node });
            }}
            elementsSelectable={true}
            // onNodesDelete={(e) => {
            //     setDelModal(true);
            // }}
            onNodeMouseEnter={(_event, node) => {
              setShowToolbar(false);
              setSelectedNode(node);
            }}
          >
            <Controls />
            <Panel>
              <div>
                <div className="d-flex flex-row align-items-center">
                  <div style={{ background: 'black' }} className="panel-buttons" />
                  <span className="font-10 bold">Question </span>
                </div>
              </div>
              <div>
                <div className="d-flex flex-row align-items-center">
                  <div style={{ background: '#00b2ff' }} className="panel-buttons" />
                  <span className="font-10 bold">Option </span>
                </div>
              </div>
              <div>
                <div className="d-flex flex-row align-items-center">
                  <div style={{ background: '#cc0000' }} className="panel-buttons" />
                  <span className="font-10 bold">Option (dead)</span>
                </div>
              </div>
              <div>
                <div className="d-flex flex-row align-items-center">
                  <div style={{ background: '#a8c3cc' }} className="panel-buttons" />
                  <span className="font-10 bold">Option (conditional)</span>
                </div>
              </div>
            </Panel>

            <MiniMap
              maskColor={'#a8a8a8'}
              pannable={true}
              nodeColor={(e) => {
                if (e?.data?.props?.question) {
                  return 'black';
                } else {
                  if (e?.data?.props?.nextQuestion) {
                    return '#00b5ff';
                  } else {
                    return '#e30000';
                  }
                }
              }}
            />
            {showToolbar &&
              (checkIfAnyLanguageIsPresentInMultiLang(selectedNode?.data?.props?.multiLanguageQuestion) ||
              selectedNode?.data?.props?.question ? (
                <QuestionToolbar
                  selectedNode={selectedNode}
                  createOption={createOption}
                  editQuestion={editQuestion}
                  populatePath={populatePath}
                  deleteQuestionProps={deleteQuestionProps}
                  createQuestion={createQuestion}
                  setNodes={setNodes}
                  setEdges={setEdges}
                  deleteDetachQuestionEdgeProps={deleteDetachQuestionEdgeProps}
                />
              ) : (
                <OptionToolbar
                  populatePath={populatePath}
                  selectedNode={selectedNode}
                  createQuestion={createQuestion}
                  editOption={editOption}
                  deleteOptionProps={deleteOptionProps}
                  deleteDetachEdgeProps={deleteDetachEdgeProps}
                />
              ))}
          </ReactFlow>
        </div>
      )}
      <Modal
        className="question-modal"
        style={{ height: '90vh', top: 20 }}
        scrollable
        show={show}
        centered
        size="lg"
        onHide={() => {
          setShow(false);
          resetStates();
        }}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>{isEditing === true ? 'Edit' : 'Add'} Question</Modal.Title> */}
          <Modal.Title>{isEditing === true ? t('Edit_Question') : t('Add_Question')}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-body">
          <Stepper activeStep={activeStep} orientation="vertical" className="ms-5">
            {Constants.dynamicSteps().map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  onClick={() => (activeStep === index ? setActiveStep(-1) : setActiveStep(index))}
                  style={{ cursor: 'pointer' }}
                  optional={index === 6 ? <Typography variant="caption">These questions can be multiple</Typography> : null}
                >
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      textAlign: langInUse === 'ar' ? 'right' : 'left',
                      marginRight: langInUse === 'ar' ? 5 : 0
                    }}
                  >
                    {step.label}
                  </div>
                </StepLabel>
                <StepContent>
                  {getContentByIndex(index)}

                  <Box sx={{ mb: 2, mt: 4 }}>
                    <div>
                      <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                        {index === Constants.dynamicSteps.length - 1 ? t('Finish') : t('Continue')}
                      </Button>
                      <Button
                        variant="contained"
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1, backgroundColor: '#969696', color: 'white' }}
                      >
                        {t('Back')}
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            onClick={() => {
              setShow(false);
              resetStates();
            }}
          >
            Close
          </Button>
          <Button variant="contained" disabled={checkDisabledButton()} onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {showCombinationModal && <CombinationModal show={showCombinationModal} setShow={setShowCombinationModal} />}
    </>
  );
}
