import {
  CLEAR_USER_APOINTMENT_LIST,
  GET_USER_APOINTMENT_LIST,
  GET_USER_DATA,
  SELECTED_APOINTMENTS,
  USER_LIST,
  GET_USER_CLINICAL_DATA,
  CLEAR_USER_CLINICAL_DATA,
  ADMIN_LIST
} from 'store/redux/actions/action.constants';
import { Day_Availability } from 'views/utilities/constant';
import { Log } from 'views/utilities/helper';

const initial_state = {
  userList: {},
  availability: Day_Availability,
  user_data: null,
  apointmentListing: null,
  userClinical: null,
  adminList: []
};

export default function (state = initial_state, action) {
  switch (action.type) {
    case USER_LIST:
      Log('listOfUser USER_LIST', action.payload);
      return {
        ...state,
        userList: action.payload
      };
    case SELECTED_APOINTMENTS:
      return {
        ...state,
        availability: action.payload
      };
    case GET_USER_DATA:
      return {
        ...state,
        user_data: action.payload
      };
    case GET_USER_APOINTMENT_LIST:
      return {
        ...state,
        apointmentListing: action.payload
      };
    case GET_USER_CLINICAL_DATA:
      return {
        ...state,
        userClinical: action.payload
      };
    case CLEAR_USER_CLINICAL_DATA:
      return {
        ...state,
        userClinical: null
      };
    case CLEAR_USER_APOINTMENT_LIST:
      return {
        ...state,
        apointmentListing: null
      };
    case ADMIN_LIST:
      return {
        ...state,
        adminList: action.payload
      };
    default:
      return state;
  }
}
