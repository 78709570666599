import { useTranslation } from 'react-i18next';

export const Constants = {
  // url

  // baseURL: 'https://dev-api.optohealth.co.uk/',
  // baseURL: 'https://dev-api-ec2.optohealth.co.uk/',
  // baseURL: 'http://localhost:3000/',
  // baseURL: 'https://13.37.243.253',
  baseURL: 'https://api.optohealth.co.uk/',

  login_api_url: '/auth/login',
  login_hospital_api_url: '/hospital/loginHospital',
  userlist_api_url: '/users/allUsers',
  updateUser_api_url: '/users/updateProfileByUserId',
  getUserApointmnetlist_api_url: '/users/getAllApppointmentsByUserId',
  loginVerifyOtp: '/users/verifyOtpForLogin',
  admin_signup_api_url: '/auth/createAdmin',
  listOfOptoAdmin_api_url: '/users/listOfOptoAdmin',
  listOfEdAdmin_api_url: '/users/listOfEdAdminByHospital',
  getUserByUPIC_api_url: '/users/getUserByUPIC',

  //doctor
  getDoctorsList_api_url: '/doctors/allDoctors',
  getApointmentsByDoctorId_api_url: '/doctors/getAppointmentsByDoctorId',
  getDoctorDataById_api_url: '/doctors/getDoctorbyid',
  createDoctor_api_url: '/auth/doctors/signup',
  updateDoctor_api_url: '/doctors/updateDoctorAvalibility',
  //
  createDisease_api_url: '/clinicalPath/addDisease',
  createDiseaseByBodyPart_api_url: '/diseaseByBody/addDiseaseByBodyParts',
  updateDiseaseByBodyPart_api_url: '/diseaseByBody/updateDiseaseByBodyParts',
  updateDisease_api_url: '/clinicalPath/updateDiseases',

  getDiseaseList_api_url: '/clinicalPath/allDiseases',
  getDiseaseById: '/clinicalPath/getDiseaseById',
  getDiseaseListByHospitalId_api_url: '/clinicalPath/allDiseasesByHospitalIDForAdmins',
  getFilteredDiseaseList_api_url: '/clinicalPath/filterDiseases',
  getQuestionByDisease_api_url: '/clinicalPath/allQuestionByDiseaseID',
  deleteQuestion_api_url: '/clinicalPath/deleteQuestion',
  getQuestionById: '/clinicalPath/getQuestionById',
  createQuestion_api_url: '/clinicalPath/addQuestion',
  updateQuestion_api_url: '/clinicalPath/updateQuestion',
  deleteQuestionOption_api_url: '/clinicalPath/deleteQuestionOption',
  getUserClinicalData_api_url: '/clinicalPath/getClinicalPathUserData',

  getHospitalsList_api_url: '/hospital/listOfHospitals',
  createHospital_api_url: '/hospital/createHospital',
  updateHospital_api_url: '/hospital/updateHospital',
  getHospitalById_api_url: '/hospital/getHospitalById',
  getHospitalDevices_api_url: '/devices/listOfDevicesByHospitalId',
  getAllDevicesList_api_url: '/devices/listOfAllDevicesForAdmin',
  updateHospitalDevice_api_url: '/devices/updateDevice',
  attachQuestionByOption_api_url: '/clinicalPath/attachQuestionToOption',
  detachQuestionByOption_api_url: '/clinicalPath/deAttachOption',
  attachDetachQuestionByQuestion_api_url: '/clinicalPath/attach_deAttach_question_from_question',
  getUsersByHospital: '/users/listOfUserByHospitalId',
  detachQuestion_api_url: '/clinicalPath/detachQuestion',
  duplicatePathway_api_url: '/clinicalPath/duplicatePathway',
  deletePathway_api_url: '/clinicalPath/deletePathway',
  getQuestionByDiseaseIDAndVersion_api_url: '/clinicalPath/getQuestionByDiseaseIDAndVersion',
  getPScore_api_url: '/clinicalPath/getListOfUsersWithPValuesOfClinicalPathWay',
  getEcdsDataByEthnicCategory: '/ecds/get_ecds_data_by_ethnic_category',
  getEcdsReferralStatues_api_url: 'ecds/get_ecds_data_by_addendance_sources',

  create_jira_ticket_api_url: 'jiraTicket/createJiraTicket',

  getPublicHealthQuestionsListForAdmin_api_url: '/publicHealthQuestion/getPublicHealthQuestionsListForAdmin',
  getPublicHealthQuestionsListForHospital_api_url: '/publicHealthQuestion/getPublicHealthQuestionsListForHospital',
  createPublicHealthQuestion_api_url: '/publicHealthQuestion/createPublicHealthQuestion',
  updatePublicHealthQuestionById_api_url: '/publicHealthQuestion/updatePublicHealthQuestionsById',
  updateHospitalPublicHealthQuestion_api_url: '/publicHealthQuestion/updateHospitalPublicHealthQuestion',

  // api response leys
  Success: 'Success',
  Fail: 'Fail',
  p1Color: '#FF2948',
  p2Color: '#FF8648',
  p3Color: '#FFDF00',
  p4Color: '#77C012',
  p5Color: '#1D8BF4',
  p0Color: '#e4e7ec',

  versions: [
    { option: 'Proxy', value: 'proxy' },
    { option: 'Paediatric', value: 'paediatric' },
    { option: 'First Person', value: 'firstPerson' }
  ],

  dynamicSteps: () => {
    const { t } = useTranslation();

    return [
      {
        label: t('Lets_get_started')
      },
      {
        label: t('Select_type_of_question')
      },
      {
        label: t('Add_multiple_options_below')
      },
      {
        label: t('Show_helper_information?')
      },
      {
        label: t('Do_you_want_to_enable_search?')
      },
      {
        label: t('Apply_any_conditions?')
      }
      // {
      //     label: "What are it's previous questions?"
      // }
    ];
  },

  languages: [
    { langName: 'English', isRTL: false, value: 'en' },
    { langName: 'Arabic', isRTL: true, value: 'ar' }
  ]
};
