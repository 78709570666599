import axios from 'axios';
import { Constants } from 'constants/Constants';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';
import request from 'utils/request';
import { Log } from 'views/utilities/helper';
import makeToast from 'views/utilities/Toast';
import { AUTH_HOSPITAL, USER_LOGOUT, AUTH_USER } from '../action.constants';
import { MENU_OPEN } from 'store/actions';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18nextInit';
export const loginUser = (userData, setIsOtpSent, setSentOtp, setShowOtp) => async (dispatch) => {
  Log('loginUser action', userData);

  try {
    const res = await request.post(Constants.login_api_url, userData);
    const { message, status, data } = res.data;
    console.log({ OTP: data?.otp });
    setSentOtp(data?.otp);

    if (!status) throw res.data;
    else {
      //   setToken(data.token, data.user);
      localStorage.setItem(LocalStorageKeys.JWT_TOKEN, data.token);
      localStorage.setItem(LocalStorageKeys.LNG, "en");
      // console.log({ data });
      setIsOtpSent(true);
      if (data?.user) {
        setShowOtp(true);
      }

      //   dispatch({
      //     type: AUTH_USER,
      //     payload: data
      //   });
    }
  } catch (e) {
    Log('error ', e);
    makeToast('error', e.message);
  }
};
export const loginHospital = (userData) => async (dispatch) => {
  // Log('login Hospital action', userData);

  try {
    const res = await request.post(Constants.login_hospital_api_url, userData);
    const { message, status, data } = res.data;

    if (!status) throw res.data;
    if (status) {
      // Log('Data', data);

      setToken(data.token, data.user);
      dispatch({
        type: AUTH_HOSPITAL,
        payload: data
      });
    }
  } catch (e) {
    // Log('error ', e);
    makeToast('error', e.message);
  }
};

const setToken = (token, user) => {
  localStorage.setItem(LocalStorageKeys.LNG, "en");
  localStorage.setItem(LocalStorageKeys.JWT_TOKEN, token);
  localStorage.setItem(LocalStorageKeys.USER_DETAILS, JSON.stringify(user));
};

export const logout = (navigate) => (dispatch) => {
  i18n.changeLanguage('en'); // Set language to English
  localStorage.removeItem(LocalStorageKeys.JWT_TOKEN);
  localStorage.removeItem(LocalStorageKeys.USER_DETAILS);
  navigate('/');
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: MENU_OPEN, id: 'default' });
};
// export const logout = (navigate) => (dispath) => {
//   const { i18n } = useTranslation();
//   i18n.changeLanguage('en'); // Set language to English
//   console.log("function-called")
//   localStorage.removeItem(LocalStorageKeys.JWT_TOKEN);
//   localStorage.removeItem(LocalStorageKeys.USER_DETAILS);
//   navigate('/');
//   dispath({
//     type: USER_LOGOUT
//   });
//   dispath({ type: MENU_OPEN, id: 'default' });
// };

export const login = async (data) => {
  try {
    const res = await request.post(
      Constants.loginVerifyOtp,
      { otp: data },
      {
        headers: {
          Authorization: localStorage.getItem(LocalStorageKeys.JWT_TOKEN)
        }
      }
    );
    if (res) {
      return res.data;
    }
  } catch (e) {
    makeToast('error', e.message);
  }
};

export const checkAuth = () => (dispatch) => {
  const token = localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
  var user = localStorage.getItem(LocalStorageKeys.USER_DETAILS);
  user = JSON.parse(user);
  console.log({ token });
  if (token) {
    dispatch({
      type: AUTH_USER,
      payload: { token: token, user: user }
    });
  }
};
