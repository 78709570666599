import { Constants } from 'constants/Constants';
import request from 'utils/request';
import { Log } from 'views/utilities/helper';
import makeToast from 'views/utilities/Toast';

export const createJiraTicket = async (data) => {
  try {
    const res = await request.post(Constants.create_jira_ticket_api_url, data);
    if (res) {
      return res;
    }
  } catch (e) {
    throw new Error(e);
  }
};
