import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { red } from '@mui/material/colors';

const AreYouSureModal = ({ isInputType, onDelete, show, onhide, title, description }) => {
  const [inputValue, setInputValue] = useState('');
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(isInputType ? true : false);

  const handleValueChange = (value) => {
    setInputValue(value);
    if (value === 'Delete') {
      setIsDeleteDisabled(false);
    } else {
      setIsDeleteDisabled(true);
    }
  };

  useEffect(() => {
    setInputValue('');
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={() => {
        setIsDeleteDisabled(isInputType ? true : false);
        setInputValue('');
        onhide();
      }}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ minWidth: 200 }}>{description}</div>
        <div style={{ marginTop: 10 }}>You cannot undo this action.</div>
        {isInputType && (
          <div style={{ marginTop: 30 }}>
            <div>Type "Delete" in the input field below to continue</div>
            <input
              value={inputValue}
              onChange={(e) => handleValueChange(e.target.value)}
              type="text"
              className="form-control"
              placeholder={'Delete'}
              style={{ marginTop: 10 }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="contained"
          style={{ backgroundColor: isDeleteDisabled ? 'lightgrey' : 'red' }}
          onClick={onDelete}
          disabled={isDeleteDisabled}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setIsDeleteDisabled(isInputType ? true : false);
            setInputValue('');
            onhide();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AreYouSureModal;
