import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListSubheader from "@material-ui/core/ListSubheader";

const languageMap = {
  en: { label: "English", dir: "ltr", active: true },
  ar: { label: "العربية", dir: "rtl", active: false }
  // fr: { label: "Français", dir: "ltr", active: false }
};

const LanguageSelect = () => {
  const selected = localStorage.getItem("i18nextLng") || "ar";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected]?.dir;
  }, [menuAnchor, selected]);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <button className="simple-btn" onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        {languageMap[selected]?.label}
        <ArrowDropDown fontSize="small" />
      </button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div className="lang-drop">
          {/*<ListSubheader>{t("select_language")}</ListSubheader>*/}
          {Object.keys(languageMap)?.map((item) => (
            <div
              button
              key={item}
              onClick={() => {
                i18next.changeLanguage(item);
                setMenuAnchor(null);
              }}
            >
              {languageMap[item].label}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
