import React, { useState } from 'react';
import { Fab, Stack, TextField, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap';
import emojies from 'assets/emojies';
import AWS from 'aws-sdk';
import makeToast from 'utils/Toaster';
import { useTranslation } from 'react-i18next';

const EmojiModal = ({ showModal, setShowModal, emoji, setEmoji }) => {
  const { t } = useTranslation();
  const customEmojiKey = showModal === 'dark' ? 'darkURL' : 'lightURL';
  const [customEmoji, setCustomEmoji] = useState(emoji[customEmojiKey]);
  const closeModel = () => setShowModal(false);
  const [userS3Config] = useState(() => JSON.parse(localStorage.getItem('user')).userS3Config);
  const [myBucket] = useState(() => {
    AWS.config.update({
      accessKeyId: userS3Config.REACT_APP_ACCESS_KEY,
      secretAccessKey: userS3Config.REACT_APP_SECRET_ACCESS_KEY
    });
    return new AWS.S3({
      params: { Bucket: userS3Config.REACT_APP_S3_BUCKET },
      region: userS3Config.REACT_APP_S3_REGION
    });
  });

  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;

    const img = e.target.files[0];
    setCustomEmoji(URL.createObjectURL(img));

    let params = {
      Body: img,
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: img.name
    };

    myBucket
      .putObject(params)
      .on('success', async (res) => {
        const url = 'https://' + process.env.REACT_APP_S3_BUCKET + '.s3.amazonaws.com/' + img.name;
        console.log('success', res);
        if (res?.httpResponse?.statusCode === 200) {
          setEmoji((p) => ({ ...p, [customEmojiKey]: url }));
          makeToast(t('success'), t('Emoji uploaded successfully'));
        } else {
          makeToast(t('error'), t('Failed to upload emoji'));
        }
      })
      .send((err) => {
        if (err) makeToast(t('error'), t('Failed to upload emoji'));
      });
  };

  return (
    <Modal show onHide={closeModel} centered size="md" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <Modal.Header closeButton>
        <Modal.Title>Emojies</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Stack direction="row" alignItems="center" mb={2} gap={2}>
          {emojies[showModal]?.map((e, i) => (
            <Fab
              component="span"
              color={emoji[showModal] === e.key ? 'error' : 'default'}
              key={i}
              onClick={() => {
                setEmoji((p) => ({ ...p, [showModal]: e.key }));
                closeModel();
              }}
            >
              <img src={e.data} alt="emoji" width={50} height={50} />
            </Fab>
          ))}
          {customEmoji && (
            <Fab
              component="span"
              color={emoji[customEmojiKey] && !emoji[showModal] ? 'error' : 'default'}
              onClick={() => {
                setEmoji((p) => ({ ...p, [showModal]: '' }));
                closeModel();
              }}
            >
              <img src={customEmoji} alt="emoji" width={50} height={50} className="rounded-circle" />
            </Fab>
          )}
        </Stack>
        <Typography variant="button" mr={2}>
          Add Custom
        </Typography>
        <TextField
          label="Custom Emoji"
          type="file"
          size="small"
          InputLabelProps={{
            shrink: true
          }}
          onChange={handleImageChange}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EmojiModal;
