import React, { useState } from 'react';
import { Button, TextField, Select, MenuItem, FormControl, Chip, Typography, Box, TextareaAutosize } from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeToast from 'utils/Toaster';
import { Constants } from 'constants/Constants';

const MultiLanguageInputField = ({
  title,
  handleMultiLanguageValues,
  baseValue,
  setBaseValue,
  basePlaceHolder,
  multiLangArray,
  setmultiLangArray,
  isTextArea,
  isForQuestions,
  defaultLanguage
}) => {
  const [defaultLanguageRecieved, setDefaultLanguageRecieved] = useState(defaultLanguage);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguageRecieved);
  const [otherLanInputValue, setOtherLanInputValue] = useState('');
  const [isEdit, setIsEdit] = useState(baseValue === '' ? false : true);
  const { t } = useTranslation();

  const handleDonenew = () => {
    let langToUse = selectedLanguage;

    if (!otherLanInputValue) {
      return makeToast('error', t('Please enter pathway name'));
    }

    if (multiLangArray.length === 0) {
      setSelectedLanguage(defaultLanguageRecieved);
      langToUse = defaultLanguageRecieved;
    }

    const payload = [...multiLangArray];
    if (payload.length === 0) {
      if (!isEdit) {
        //setBaseValue(otherLanInputValue);
      }
      payload.push({ language: langToUse, content: otherLanInputValue });
    } else {
      const langObject = checkIfLanguageIsPresent(langToUse, payload);
      if (langObject) {
        setOtherLanInputValue('');
        return makeToast('error', t('Language_is_already_present'));
      } else {
        if (langToUse === defaultLanguageRecieved) {
          if (!isEdit) {
            //setBaseValue(otherLanInputValue);
          }
          payload.push({ language: langToUse, content: otherLanInputValue });
        } else {
          payload.push({ language: langToUse, content: otherLanInputValue });
        }
      }
    }
    setmultiLangArray(payload);
    setOtherLanInputValue('');
  };
  const checkIfLanguageIsPresent = (lang, array) => {
    let arrayLang;
    if (array) {
      arrayLang = [...array];
    } else {
      arrayLang = [...multiLangArray];
    }
    //const arrayLang = [...multiLangArray];
    const objFound = arrayLang.find((obj) => obj.language === lang);
    //console.log('OBJ FOUND => ', objFound);
    if (objFound) {
      return objFound;
    } else {
      return undefined;
    }
  };
  const handleDelete = (index, isCalledFromEdit) => {
    const payload = [...multiLangArray];

    if (payload[index].language === defaultLanguageRecieved) {
      if (!isEdit) {
        //setBaseValue('');
      }
    }
    payload.splice(index, 1);
    setmultiLangArray(payload);
    const defaultLangObj = checkIfLanguageIsPresent(defaultLanguageRecieved, payload);
    if (defaultLangObj) {
      if (!isEdit) {
        //setBaseValue(defaultLangObj.content);
      }
    } else {
      if (!isEdit) {
        //setBaseValue('');
      }
    }
    if (payload.length === 0) {
      if (!isEdit) {
        //setBaseValue('');
      }
      if (!isCalledFromEdit) {
        setOtherLanInputValue('');
        setSelectedLanguage(defaultLanguageRecieved);
      }
    }
  };
  const getLangFullName = (name) => {
    const langObjFound = Constants.languages.filter((obj) => obj.value === name);
    if (langObjFound) {
      return langObjFound[0].langName;
    } else {
      return 'Not recognised';
    }
    return 'failed';
  };
  const getLanguageRtlStatus = () => {
    const langObj = Constants.languages.filter((obj) => obj.value === selectedLanguage);
    if (langObj[0].isRTL) {
      return 'right';
    } else {
      return 'left';
    }
  };
  const handleEdit = (index) => {
    console.log('EDIT CALLED ON => ', index);
    setOtherLanInputValue(multiLangArray[index].content);
    setSelectedLanguage(multiLangArray[index].language);
    handleDelete(index, true);
  };
  return (
    <div>
      {isForQuestions ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Box sx={{ flex: 0.97 }}>
            <p className="headings mt-3">{title}</p>
          </Box>
          <Box>
            <Select
              className="ms-3 w-200 m-2"
              value={multiLangArray.length === 0 && otherLanInputValue === '' ? defaultLanguageRecieved : selectedLanguage}
              id="demo-simple-select"
              disabled={multiLangArray.length == 0 && otherLanInputValue === ''}
              onChange={(e) => {
                setSelectedLanguage(e.target.value);
              }}
            >
              {Constants.languages?.map((e, i) => (
                <MenuItem key={i} value={e.value}>{e.langName}</MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      ) : (
        <label>
          <strong>
            <p>{title}</p>
          </strong>
        </label>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          {isTextArea ? (
            isForQuestions ? (
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                maxLength={500}
                className="textarea-styles"
                placeholder={basePlaceHolder}
                onChange={(e) => setOtherLanInputValue(e.target.value)}
                value={otherLanInputValue}
                style={{ textAlign: getLanguageRtlStatus() }}
              />
            ) : (
              <textarea
                value={otherLanInputValue}
                onChange={(e) => setOtherLanInputValue(e.target.value)}
                type="text"
                className="form-control"
                placeholder={basePlaceHolder}
                style={{ textAlign: getLanguageRtlStatus() }}
              />
            )
          ) : (
            <>
              <input
                value={otherLanInputValue}
                onChange={(e) => setOtherLanInputValue(e.target.value)}
                type="text"
                className="form-control"
                placeholder={basePlaceHolder}
                style={{ textAlign: getLanguageRtlStatus() }}
              />
            </>
          )}
        </Box>
        <Box>
          {isForQuestions ? null : (
            <Select
              className="ms-3 w-200 m-2"
              value={multiLangArray.length === 0 && otherLanInputValue === '' ? defaultLanguageRecieved : selectedLanguage}
              id="demo-simple-select"
              disabled={multiLangArray.length == 0 && otherLanInputValue === ''}
              onChange={(e) => {
                setSelectedLanguage(e.target.value);
              }}
            >
              {Constants.languages?.map((e) => (
                <MenuItem value={e.value}>{e.langName}</MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </Box>
      <Button className="me-2 mt-2" variant="contained" onClick={handleDonenew}>
        {t('Done')}
      </Button>
      <div>
        {multiLangArray.map((langObj, index) => {
          return (
            <div key={index} className="mt-2" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {/* <Typography>Language: {getLangFullName(langObj.language)}</Typography> */}
                <Chip
                  style={{
                    height: 50
                  }}
                  //label={'Language: ' + getLangFullName(langObj.language) + '\nContent: ' + langObj.content}
                  variant="outlined"
                  onDelete={() => handleDelete(index)}
                  label={
                    <section>
                      <div className="mt-2 mb-2 text-wrap text-break">{getLangFullName(langObj.language) + ': ' + langObj.content}</div>
                    </section>
                  }
                />
                <div style={{ marginLeft: 0 }}>
                  <button
                    onClick={() => {
                      handleEdit(index);
                    }}
                    style={{
                      marginTop: 10,
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    <img
                      style={{
                        width: '24px',
                        height: '24px'
                      }}
                      src={require('../assets/images/editIcon.png')}
                    />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiLanguageInputField;
