import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationAR from "./assets/locales/ar/translation.json";
// import translationFR from "../public/assets/locales/fr/translation";

const fallbackLng = [localStorage.getItem("i18nextLng")];
const availableLanguages = ["en", "ar"];
console.log("LanguageDetector",localStorage.getItem("i18nextLng"))
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  },
  // fr: {
  //   translation: translationFR
  // }
};
console.log("LanguageDetector",initReactI18next)
if(initReactI18next==="en-US")
{
  initReactI18next==="en"
}
console.log("initReactI18next",LanguageDetector)
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
