import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useState } from 'react';
import axios from 'axios';
import { Constants } from '../../../../constants/Constants';
import Logo from '../../../../assets/images/logo.svg';
import User from '../../../../assets/images/pdf_user_icon.jpg';
import { useTranslation } from 'react-i18next';

export default function ClinicalPathTester({ data }) {
  const params = useParams();
  const [userData, setUserData] = useState();
  const [records, setRecords] = useState([]);
  const [color, setColor] = useState('#e4e7ec');
  const [timer, setTimer] = useState('N/A');
  const [type, setType] = useState('N/A');
  const [violence, setViolence] = useState(false);
  const [security, setSecurity] = useState(false);
  const [voilanceAnswer, setVoilanceAnswer] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [bookedForMe, setBookedForMe] = useState(false);
  const [p, setP] = useState('');
  const [allergies, setAllergies] = useState('');
  const [matchedAllergiesList, setMatchedAllergiesList] = useState([]);
  const [childPresence, setChildPresence] = useState(false);
  const [orderedQuestions, setOrderedQuestions] = useState({ p0: [], p1: [], p2: [], p3: [], p4: [], p5: [] });
  const { t } = useTranslation();

  const headers = {
    'content-type': 'application/json'
  };

  const getTime = (myTime) => {
    const t = new Date(parseInt(myTime));

    const m = t.getMinutes();
    const s = t.getSeconds();

    return `${m}m ${s}s`;
  };

  const matchRecordsWithAllergyAlerts = (records = [], allergyAlert = []) => {
    allergyAlert = allergyAlert.filter(({ isActive, isDeleted }) => isActive && !isDeleted);
    console.log('disease allergyAlerts -->', allergyAlert);
    const isMatched =
      allergyAlert.length > 0 &&
      allergyAlert.every(({ allergyAlert }) =>
        allergyAlert?.every((c) => {
          return records.find((record) => c.question === record.questionId && c.option === record.selectedOptionId);
        })
      );
    console.log('AllergyAlert isMatched ==> ', isMatched);
    if (isMatched) setMatchedAllergiesList(allergyAlert[0].allergies);
  };

  const matchRecordsWithCombination = (records = [], combination = []) => {
    combination = combination.filter(({ isActive, isDeleted }) => isActive && !isDeleted);
    console.log('disease combinations -->', combination);
    const isMatched =
      combination.length > 0 &&
      combination.every(({ combination }) =>
        combination?.every((c) => {
          return records.find((record) => c.question === record.questionId && c.option === record.selectedOptionId);
        })
      );
    console.log('Combination isMatched ==> ', isMatched);
    if (!isMatched) return false;

    const pValue = combination[0].overAllCode?.toUpperCase();
    setP(pValue);
    switch (pValue) {
      case 'P1':
        setColor(Constants.p1Color);
        break;
      case 'P2':
        setColor(Constants.p2Color);
        break;
      case 'P3':
        setColor(Constants.p3Color);
        break;
      case 'P4':
        setColor(Constants.p4Color);
        break;
      case 'P5':
        setColor(Constants.p5Color);
        break;
      default:
        return false;
    }

    return true;
  };

  const makeApiCall = async () => {
    console.log('API CALLED => ', params.id);
    console.log('API CALLED => ', typeof params.id);

    await axios
      .post(`${Constants.baseURL}auth/getClinicalPathUserDataForNonAuth`, { userId: params.id }, { headers })
      .then((e) => {
        console.log('IN THEN BLCK');
        let uData = e.data.data;
        console.log({ uData });
        setUserData(uData.user);
        setRecords(uData.record);
        console.log('u data records -->', uData.record);
        setTimer(uData?.timer ? uData?.timer : 'N/A');
        setType(uData?.problem_type ? uData?.problem_type : 'N/A');

        let _p5 = uData?.record?.filter((e) => e.code?.toLowerCase() === 'p5');
        let _p4 = uData?.record?.filter((e) => e.code?.toLowerCase() === 'p4');
        let _p3 = uData?.record?.filter((e) => e.code?.toLowerCase() === 'p3');
        let _p2 = uData?.record?.filter((e) => e.code?.toLowerCase() === 'p2');
        let _p1 = uData?.record?.filter((e) => e.code?.toLowerCase() === 'p1');
        let _p0 = uData?.record?.filter((e) => e.code?.toLowerCase() === 'p0');

        setOrderedQuestions({ p0: _p0, p1: _p1, p2: _p2, p3: _p3, p4: _p4, p5: _p5 });
        console.log('orderedQuestions', { p0: _p0, p1: _p1, p2: _p2, p3: _p3, p4: _p4, p5: _p5 });

        let domVio = uData?.record?.find((e) =>
          e.question.includes('Do you think you are at risk of domestic abuse?' || 'هل تعتقد أنك معرض لخطر العنف المنزلي؟')
        );
        console.log('DOMVIO => ', domVio);

        if (domVio && domVio.answer.toLowerCase() === 'yes') setViolence(true);

        let socSec = uData?.record?.find((e) =>
          e.question.includes('Do you have any support from the following?' || 'هل لديك أي دعم مما يلي؟')
        );
        if (socSec && socSec.answer) {
          if (socSec.answer.toLowerCase().includes('other')) {
            let serSupport = uData?.record?.find((e) =>
              e.question.includes('Please tell us what services you have support from' || 'يُرجى إخبارنا عن الخدمات التي يتلقى منها الدعم')
            );
            if (serSupport && serSupport.answer) {
              setServiceName(serSupport.answer);
            } else {
              setServiceName('Not Described');
            }
          } else {
            setServiceName(socSec.answer);
          }
        }
        //if (socSec && socSec.answer.toLowerCase().includes('social services')) setSecurity(true);

        let userAllergies = uData?.record?.find((e) =>
          e.question.toLowerCase().includes('please tell us what allergies you have' || 'من فضلك قل لنا ما هي الحساسية التي لديك')
        );
        if (userAllergies) setAllergies(userAllergies.answer);

        let _childPresence = uData?.record?.find((e) =>
          e.question.toLowerCase().includes('were there any children present' || 'هل كان هناك أطفال؟')
        );
        if (_childPresence) setChildPresence(true);

        if (uData?.user?.bookingFor === 'I am booking in for myself' || uData?.user?.bookingFor === 'أحجز لنفسي') {
          setBookedForMe(true);
        }

        if (uData?.disease) matchRecordsWithAllergyAlerts(uData?.record, uData?.disease?.allergyAlert);
        if (uData?.disease && matchRecordsWithCombination(uData?.record, uData?.disease?.combination)) return;

        const p1 = uData.record.find((element) => element['code'].toLowerCase() === 'p1');
        const p2 = uData.record.find((element) => element['code'].toLowerCase() === 'p2');
        const p3 = uData.record.find((element) => element['code'].toLowerCase() === 'p3');
        const p4 = uData.record.find((element) => element['code'].toLowerCase() === 'p4');
        const p5 = uData.record.find((element) => element['code'].toLowerCase() === 'p5');

        if (p1) {
          setColor(Constants.p1Color);
          setP('P1');
        } else if (p2) {
          setColor(Constants.p2Color);
          setP('P2');
        } else if (p3) {
          setColor(Constants.p3Color);
          setP('P3');
        } else if (p4) {
          setColor(Constants.p4Color);
          setP('P4');
        } else if (p5) {
          setColor(Constants.p5Color);
          setP('P5');
        }
      })
      .catch((e) => {
        console.log('Error while api call', e);
      });
  };

  useEffect(() => {
    if (params) {
      makeApiCall();
    }
  }, [params]);

  const getYesNo = (value) => {
    if (value) return `${t('Yes')}`;
    else return `${t('No')}`;
  };

  const getDate = () => {
    if (userData?.dob) {
      let newDob = `${userData?.dob?.slice(0, 4)}-${userData?.dob?.slice(4, 6)}-${userData?.dob?.slice(6, 8)}`;

      return newDob;
    }
    return '-';
  };

  const ref = useRef();

  useEffect(() => {
    console.log('USER DATA => ', userData);
  }, [userData]);

  return (
    <div className="App">
      <div className="element-to-print" ref={ref}>
        <div className="px-5">
          {matchedAllergiesList.length > 0 && (
            <div className="d-flex my-3 gap-2 px-2">
              {matchedAllergiesList.map((a, i) => {
                return (
                  <div
                    key={i}
                    className="rounded py-1"
                    style={{
                      backgroundColor: '#004361',
                      color: '#fff',
                      flex: 1,
                      textAlign: 'center',
                      fontSize: 14,
                      fontWeight: 600
                    }}
                  >
                    {a}
                  </div>
                );
              })}
            </div>
          )}
          <div className="logos">
            <img className="user" src={User} alt="user" />
            <img className="logo" src={Logo} alt="logo" />
          </div>
          <div className="box-1">
            <div className="row">
              <div className="col-9">
                <div className="pds_box">
                  <h3>PDS</h3>
                  <table>
                    <tr>
                      <th className="thead">{t('First_Name')}</th>
                      <td className="tdata">{`${userData?.firstname ? userData?.firstname?.substring(0, 30) : '-'}`}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Surname')}</th>
                      <td className="tdata">{`${userData?.lastname ? userData?.lastname?.substring(0, 30) : '-'}`}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Date_of_Birth')}</th>
                      <td className="tdata">{userData?.dob}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('NHS_No')}</th>
                      <td className="tdata">
                        {userData?.nhsNumber && parseInt(userData?.nhsNumber) > 0 ? userData?.nhsNumber : `${t('Not_Found')}`}
                      </td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Gender')} </th>
                      <td className="tdata">{userData?.gender}</td>
                    </tr>
                    {userData?.phone && userData?.phone !== -1 && userData?.phone !== '-1' && (
                      <tr>
                        <th className="thead">{t('Phone_Number')} </th>
                        <td className="tdata">{userData?.phone ? `+${userData?.phone?.toString()?.substring(0, 30)}` : '-'}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="thead">{t('Postal_Code')} </th>
                      <td className="tdata">{userData?.postCode ? userData?.postCode?.substring(0, 30) : '-'}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Identity')}</th>
                      <td className="tdata">{userData?.identifyGender}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Time_Taken')} </th>
                      {console.log({ timer })}
                      <td className="tdata">{timer && timer !== 'N/A' ? getTime(timer) : '-'}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-3">
                <div className="complaint">
                  <h3>{t('Presenting_Complaint')}</h3>
                  <div className="pain" style={{ height: userData?.phone ? 255 : 230 }}>
                    <h4>{type}</h4>
                    <div className="circle" style={{ backgroundColor: color }}>
                      <h4>{p}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-1 box-2">
            <div className="row">
              <div className="col-md-12">
                <div className="pds_box">
                  <h3>{t('key_safety_information')}</h3>
                  <table>
                    <tr>
                      <th className="thead">{t('Booking_in_for_myself')}</th>
                      <td className="tdata">{getYesNo(bookedForMe)}</td>
                    </tr>
                    {!bookedForMe && (
                      <>
                        <tr>
                          <th className="thead">{t('Is_this_for_a_child')}</th>
                          <td className="tdata">{getYesNo(userData?.isBookingForChild)}</td>
                        </tr>
                        <tr>
                          <th className="thead">{t('Relationship')}</th>
                          <td className="tdata">{userData?.relationshipToPerson}</td>
                        </tr>
                        <tr>
                          <th className="thead">{t('First_Name')}</th>
                          <td className="tdata">{userData?.bookingByDeatils?.firstname}</td>
                        </tr>
                        <tr>
                          <th className="thead">{t('Surname')}</th>
                          <td className="tdata">{userData?.bookingByDeatils?.lastname}</td>
                        </tr>
                        <tr>
                          <th className="thead">{t('Mobile_number')}</th>
                          <td className="tdata">{'+' + userData?.bookingByDeatils?.phone}</td>
                        </tr>
                        {userData?.isBookingForChild && (
                          <tr>
                            <th className="thead">{t('What_school_or_nursery_does_the_child_go_to')}</th>
                            <td className="tdata">{userData?.bookingByDeatils?.schoolOrNurery.substring(0, 25)}</td>
                          </tr>
                        )}
                      </>
                    )}
                    <tr>
                      <th className="thead">{userData?.ecds?.Cough_ques || t('Have_you_had_a_recent_cough')}</th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Cough)}</td>
                    </tr>
                    <tr>
                      <th className="thead">{userData?.ecds?.Temperature_ques || t('Have_you_had_a_recent_temperature')}</th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Temperature)}</td>
                    </tr>
                    <tr>
                      <th className="thead">{userData?.ecds?.Covid19_ques || t('Have_you_recently_tested_positive_for_Covid19')}</th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Covid19)}</td>
                    </tr>
                    <tr>
                      <th className="thead">{userData?.ecds?.Chemotherapy_ques || t('Are_you_having_chemotherapy')}</th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Chemotherapy)}</td>
                    </tr>
                    <tr>
                      <th className="thead">{userData?.ecds?.Radiotherapy_ques || t('Are_you_having_radiotherapy')}</th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Radiotherapy)}</td>
                    </tr>
                    <tr>
                      <th className="thead">
                        {userData?.ecds?.Immunosuppressed_ques || t('Are_you_having_immunotherapy_or_are_immunosuppressed')}
                      </th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Immunosuppressed)}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Do_you_think_you_are_at_risk_of_domestic_violence')}</th>
                      <td className="tdata">{getYesNo(violence)}</td>
                    </tr>
                    {type.toLowerCase() === 'i have been assaulted' && (
                      <tr>
                        <th className="thead">{t('Were_there_any_children_present')}</th>
                        <td className="tdata">{getYesNo(childPresence)}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="thead">{t('Do_you_have_any_support_from_the_Social_Services')}</th>
                      {/* <td className="tdata">{getYesNo(security)}</td> */}
                      <td className="tdata">{serviceName}</td>
                    </tr>
                    <tr>
                      <th className="thead">{t('Please_tell_us_what_allergies_you_have')}</th>
                      <td className="tdata">{allergies === '' ? `${t('No')}` : allergies}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="box-1 box-3">
            <div className="row">
              <div className="col-md-12">
                <div className="pds_box">
                  <h3>{t('Clinical_assessment')}</h3>
                  <table>
                    {orderedQuestions.p1.map((e, i) => (
                      <tr key={i}>
                        <th className="thead p1">{e.question}</th>
                        <td className="tdata p1">{e.answer}</td>
                      </tr>
                    ))}

                    {orderedQuestions.p2.map((e, i) => (
                      <tr key={i}>
                        <th className="thead p2">{e.question}</th>
                        <td className="tdata p2">{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p3.map((e, i) => (
                      <tr key={i}>
                        <th className="thead p3">{e.question}</th>
                        <td className="tdata p3">{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p4.map((e, i) => (
                      <tr key={i}>
                        <th className="thead p4">{e.question}</th>
                        <td className="tdata p4">{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p5.map((e, i) => (
                      <tr key={i}>
                        <th className="thead p5">{e.question}</th>
                        <td className="tdata p5">{e.answer}</td>
                      </tr>
                    ))}
                    {orderedQuestions.p0.map((e, i) => (
                      <tr key={i}>
                        <th className="thead p0">{e.question}</th>
                        <td className="tdata p0">{e.answer}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="box-1 box-2">
            <div className="row">
              <div className="col-md-12">
                <div className="pds_box">
                  <h3>ECDS</h3>
                  <table>
                    <tr>
                      <th className="thead">{userData?.ecds?.Ethnic_Category_ques || t('Please_tell_us_your_ethnicity')}</th>
                      <td className="tdata">{userData?.ecds?.Ethnic_Category?.answer}</td>
                    </tr>
                    <tr>
                      <th className="thead">{userData?.ecds?.Spoken_Language_ques || t('What_is_your_native_spoken_language')}</th>
                      <td className="tdata">{userData?.ecds?.Spoken_Language?.answer}</td>
                    </tr>
                    <tr>
                      <th className="thead">
                        {userData?.ecds?.Interpreter_Language_Status_ques ||
                          t('Which_interpreter_language_would_you_need_to_help_you_today')}
                      </th>
                      <td className="tdata">
                        {userData?.ecds?.Interpreter_Language?.answer ? userData?.ecds?.Interpreter_Language?.answer : `${t('None')}`}
                      </td>
                    </tr>
                    <tr>
                      <th className="thead">{userData?.ecds?.Accommodation_Status_ques || t('What_is_your_accommodation_status')}</th>
                      <td className="tdata">{userData?.ecds?.Accommodation_Status?.answer}</td>
                    </tr>
                    <tr>
                      <th className="thead">{userData?.ecds?.Arrival_Mode_ques || t('How_did_you_travel_here_today')}</th>
                      <td className="tdata">{userData?.ecds?.Arrival_Mode?.answer}</td>
                    </tr>
                    <tr>
                      <th className="thead">
                        {userData?.ecds?.Addendance_Sources_Status_ques || t('Has_anyone_told_you_to_come_here_today')}
                      </th>
                      <td className="tdata">{userData?.ecds?.Addendance_Sources_Status ? `${t('Yes')}` : `${t('No')}`}</td>
                    </tr>
                    {userData?.ecds?.Addendance_Sources_Status && (
                      <tr>
                        <th className="thead">{userData?.ecds?.Addendance_Sources_ques || t('Who_told_you_to_come_here')}</th>
                        <td className="tdata">{userData?.ecds?.Addendance_Sources?.answer}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="thead">{userData?.ecds?.Temperature_ques || t('Temperature')}</th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Temperature)}</td>
                    </tr>
                    <tr>
                      <th className="thead">
                        {userData?.ecds?.Attendance_Activity_ques ||
                          t('Have_you_been_to_this_Emergency_Department_in_the_last_7_days_for_the_same_problem_you_have_today')}
                      </th>
                      <td className="tdata">{userData?.ecds?.Attendance_Activity?.answer}</td>
                    </tr>
                    <tr>
                      <th className="thead">
                        {userData?.ecds?.Support_Communicating_Status_ques || `Do you identify as “deaf” or “hard of hearing”?`}
                      </th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Support_Communicating_Status)}</td>
                    </tr>
                    {userData?.ecds?.Support_Communicating_Status === true && (
                      <tr>
                        <th className="thead">
                          {userData?.ecds?.Support_Communicating_ques || `Do you need support with communicating from the following?`}
                        </th>
                        <td className="tdata">{userData?.ecds?.Support_Communicating?.answer}</td>
                      </tr>
                    )}
                    <tr>
                      {/* <th className="thead">Injury</th> */}
                      <th className="thead">{userData?.ecds?.Injury_ques || t('Injury')}</th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Injury)}</td>
                    </tr>

                    <tr>
                      <th className="thead">
                        {userData?.ecds?.Outside_UK_ques || `Have you lived outside the UK at all in the past 12 months?`}
                      </th>
                      <td className="tdata">{getYesNo(userData?.ecds?.Outside_UK)}</td>
                    </tr>

                    {userData?.ecds.hasOwnProperty('Military') && (
                      <tr>
                        {/* {bookedForMe ? (
                          <th className="thead">Are you a military veteran?</th>
                        ) : (
                          <th className="thead">Are they a military veteran?</th>
                        )} */}
                        <th className="thead">{userData?.ecds?.Military_ques || `Are they a military veteran?`}</th>
                        <td className="tdata">{getYesNo(userData?.ecds?.Military)}</td>
                      </tr>
                    )}

                    {userData?.ecds?.Injury && (
                      <>
                        <tr>
                          <th className="thead">{userData?.ecds?.Place_Of_Injury_ques || t('Where_did_the_injury_take_place')}</th>
                          <td className="tdata">{userData?.ecds?.Place_Of_Injury?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">{userData?.ecds?.Injury_time_ques || t('Time_of_Injury')}</th>
                          <td className="tdata">{userData?.ecds?.Injury_time}</td>
                        </tr>
                        <tr>
                          <th className="thead">{userData?.ecds?.Injury_date_ques || t('Date_of_Injury')}</th>
                          <td className="tdata">{userData?.ecds?.Injury_date}</td>
                        </tr>
                        <tr>
                          <th className="thead">{userData?.ecds?.Injury_Intent_ques || t('How_did_the_injury_happen')}</th>
                          <td className="tdata">{userData?.ecds?.Injury_Intent?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">
                            {userData?.ecds?.Injury_Acrtivity_Status_ques || t('What_were_you_doing_when_you_got_injured')}
                          </th>
                          <td className="tdata">{userData?.ecds?.Injury_Acrtivity_Status?.answer}</td>
                        </tr>
                        <tr>
                          <th className="thead">{userData?.ecds?.Injury_Activity_Type_ques || t('What_were_you_doing_to_get_injured')}</th>
                          <td className="tdata">{userData?.ecds?.Injury_Activity_Type?.answer}</td>
                        </tr>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="box-5">
            <h3>{t('Guide_to_Answer_Types')}</h3>
            <div className="guide_box">
              <p className="p1">{t('P1_Immediate')}</p>
              <p className="p2">{t('P2_Very_urgent')}</p>
              <p className="p3">{t('P3_Urgent')}</p>
              <p className="p4">{t('P4_Standard')}</p>
              <p className="p5">{t('P5_Non_urgent')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
