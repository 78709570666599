// assets
import { IconDashboard } from '@tabler/icons';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { useTranslation } from "react-i18next";
// constant
const icons = { DashboardOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = () => {
  const { t } = useTranslation();
  return {
      id: 'dashboard',
      // title: 'Dashboard',
      type: 'group',
      children: [
        {
          id: 'default',
          title: t("Dashboard"),
          type: 'item',
          url: '/',
          icon: icons.DashboardOutlinedIcon,
          breadcrumbs: false
        }
      ]
    
  };

};

export default dashboard;
