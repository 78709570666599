import { HOSPITALS_LIST, HOSPITAL_BY_ID } from 'store/redux/actions/action.constants';

const initial_state = {
    hospitalsListing: null
};

export default function (state = initial_state, action) {
    switch (action.type) {
        case HOSPITALS_LIST:
            return {
                ...state,
                hospitalsListing: action.payload
            };
        case HOSPITAL_BY_ID:
            return {
                ...state,
                hospitaById: action.payload
            };
        default:
            return state;
    }
}
