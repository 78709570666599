import React from 'react';
import { Typography } from '@mui/material';
import './style.scss';

import ClinicalIncidentFormModal from './ClinicalIncidentFormModal';
import ItIncidentFormModal from './ItIncidentFormModal';

const Incidents = () => {
  return (
    <>
      <div className="h-auto incident-page">
        <div className="row align-items-center mb-3">
          <div className="col-md-6 my-2">
            <Typography variant="h4" sx={{ color: '#034562' }}>
              What can we help you with?
            </Typography>
          </div>
        </div>
        <div className="total-consultation" style={{ minHeight: '100vh' }}>
          <div className="d-flex flex-column justify-content-start gap-4 align-items-start p-2 w-100">
            <ClinicalIncidentFormModal />
            <ItIncidentFormModal />
          </div>
        </div>
      </div>
    </>
  );
};

export default Incidents;
