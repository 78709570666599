import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login')));
const LoginHospital = Loadable(lazy(() => import('views/pages/authentication/authentication3/LoginHospital')));
const UserClinicalPathTester = Loadable(lazy(() => import('views/dashboard/users/_part/ClinicalPathTester')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: '/',
      element: <LoginHospital />
    },
    {
      path: '/clinical-path/:id',
      element: <UserClinicalPathTester />
    },
    {
      path: '*',
      element: <AuthLogin />
    }

    // {
    //     path: '/pages/register/register3',
    //     element: <AuthRegister3 />
    // }
  ]
};
// const AuthenticationRoutes = {
//   path: '*',
//   element: <AuthLogin />
// };

export default AuthenticationRoutes;
