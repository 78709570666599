export const Day_Availability = [
    {
        dateOfAvailability: '2022-05-24T12:00:00Z',
        status: true,
        slots: [
            {
                startTime: '00:00',
                endTime: '00:30',
                status: 'unavailable',
                index: '0',
                timezone: 'am',
                label: '00:00'
            },
            {
                startTime: '00:30',
                endTime: '01:00',
                status: 'unavailable',
                index: '1',
                timezone: 'am',

                label: '00:30'
            },

            {
                startTime: '01:00',
                endTime: '01:30',
                status: 'unavailable',
                index: '2',
                timezone: 'am',

                label: '01:00'
            },
            {
                startTime: '01:30',
                endTime: '02:00',
                status: 'unavailable',
                index: '3',
                timezone: 'am',

                label: '01:30'
            },

            {
                startTime: '02:00',
                endTime: '02:30',
                status: 'unavailable',
                index: '4',
                timezone: 'am',

                label: '02:00'
            },

            {
                startTime: '02:30',
                endTime: '03:00',
                status: 'unavailable',
                index: '5',
                timezone: 'am',

                label: '02:30'
            },
            {
                startTime: '03:00',
                endTime: '03:30',
                status: 'unavailable',
                index: '6',
                timezone: 'am',

                label: '03:00'
            },
            {
                startTime: '03:30',
                endTime: '04:00',
                status: 'unavailable',
                index: '7',
                timezone: 'am',

                label: '03:30'
            },

            {
                startTime: '04:00',
                endTime: '04:30',
                status: 'unavailable',
                index: '8',
                timezone: 'am',

                label: '04:00'
            },
            {
                startTime: '04:30',
                endTime: '05:00',
                status: 'unavailable',
                index: '9',
                timezone: 'am',

                label: '04:30'
            },
            {
                startTime: '05:00',
                endTime: '05:30',
                status: 'unavailable',
                index: '10',
                timezone: 'am',

                label: '05:00'
            },
            {
                startTime: '05:30',
                endTime: '06:00',
                status: 'unavailable',
                index: '11',
                timezone: 'am',

                label: '05:30'
            },

            {
                startTime: '06:00',
                endTime: '06:30',
                status: 'unavailable',
                index: '12',
                timezone: 'am',

                label: '06:00'
            },
            {
                startTime: '06:30',
                endTime: '07:00',
                status: 'unavailable',
                index: '13',
                timezone: 'am',

                label: '06:30'
            },

            {
                startTime: '07:00',
                endTime: '07:30',
                status: 'unavailable',
                index: '14',
                timezone: 'am',

                label: '07:00'
            },
            {
                startTime: '07:30',
                endTime: '08:00',
                status: 'unavailable',
                index: '15',
                timezone: 'am',

                label: '07:30'
            },
            {
                startTime: '08:00',
                endTime: '08:30',
                status: 'unavailable',
                index: '16',
                timezone: 'am',

                label: '08:00'
            },
            {
                startTime: '08:30',
                endTime: '09:00',
                status: 'unavailable',
                index: '17',
                timezone: 'am',

                label: '08:30'
            },

            {
                startTime: '09:00',
                endTime: '09:30',
                status: 'unavailable',
                index: '18',
                timezone: 'am',

                label: '09:00'
            },

            {
                startTime: '09:30',
                endTime: '10:00',
                status: 'unavailable',
                index: '19',
                timezone: 'am',

                label: '09:30'
            },

            {
                startTime: '10:00',
                endTime: '10:30',
                status: 'unavailable',
                index: '20',
                timezone: 'am',

                label: '10:00'
            },
            {
                startTime: '10:30',
                endTime: '11:00',
                status: 'unavailable',
                index: '21',
                timezone: 'am',

                label: '10:30'
            },

            {
                startTime: '11:00',
                endTime: '11:30',
                status: 'unavailable',
                index: '22',
                timezone: 'am',

                label: '11:00'
            },
            {
                startTime: '11:30',
                endTime: '12:00',
                status: 'unavailable',
                index: '23',
                timezone: 'am',

                label: '11:30'
            },
            {
                startTime: '12:00',
                endTime: '12:30',
                status: 'unavailable',
                index: '24',
                timezone: 'pm',

                label: '12:00'
            },
            {
                startTime: '12:30',
                endTime: '01:00',
                status: 'unavailable',
                index: '25',
                timezone: 'pm',

                label: '12:30'
            },

            {
                startTime: '13:00',
                endTime: '01:30',
                status: 'unavailable',
                index: '26',
                timezone: 'pm',

                label: '13:00'
            },
            {
                startTime: '13:30',
                endTime: '14:00',
                status: 'unavailable',
                index: '27',
                timezone: 'pm',

                label: '13:30'
            },

            {
                startTime: '14:00',
                endTime: '14:30',
                status: 'unavailable',
                index: '28',
                timezone: 'pm',

                label: '14:00'
            },

            {
                startTime: '14:30',
                endTime: '15:00',
                status: 'unavailable',
                index: '29',
                timezone: 'pm',

                label: '14:30'
            },
            {
                startTime: '15:00',
                endTime: '15:30',
                status: 'unavailable',
                index: '30',
                timezone: 'pm',

                label: '15:00'
            },
            {
                startTime: '15:30',
                endTime: '16:00',
                status: 'unavailable',
                index: '31',
                timezone: 'pm',

                label: '15:30'
            },

            {
                startTime: '16:00',
                endTime: '16:30',
                status: 'unavailable',
                index: '32',
                timezone: 'pm',

                label: '16:00'
            },
            {
                startTime: '16:30',
                endTime: '17:00',
                status: 'unavailable',
                index: '33',
                timezone: 'pm',

                label: '16:30'
            },
            {
                startTime: '17:00',
                endTime: '17:30',
                status: 'unavailable',
                index: '34',
                timezone: 'pm',

                label: '17:00'
            },
            {
                startTime: '17:30',
                endTime: '18:00',
                status: 'unavailable',
                index: '35',
                timezone: 'pm',

                label: '17:30'
            },

            {
                startTime: '18:00',
                endTime: '18:30',
                status: 'unavailable',
                index: '36',
                timezone: 'pm',

                label: '18:00'
            },
            {
                startTime: '18:30',
                endTime: '19:00',
                status: 'unavailable',
                index: '37',
                timezone: 'pm',

                label: '18:30'
            },

            {
                startTime: '19:00',
                endTime: '19:30',
                status: 'unavailable',
                index: '38',
                timezone: 'pm',

                label: '19:00'
            },
            {
                startTime: '19:30',
                endTime: '20:00',
                status: 'unavailable',
                index: '39',
                timezone: 'pm',

                label: '19:30'
            },
            {
                startTime: '20:00',
                endTime: '20:30',
                status: 'unavailable',
                index: '40',
                timezone: 'pm',

                label: '20:00'
            },
            {
                startTime: '20:30',
                endTime: '21:00',
                status: 'unavailable',
                index: '41',
                timezone: 'pm',

                label: '20:30'
            },

            {
                startTime: '21:00',
                endTime: '21:30',
                status: 'unavailable',
                index: '42',
                timezone: 'pm',

                label: '21:00'
            },

            {
                startTime: '21:30',
                endTime: '22:00',
                status: 'unavailable',
                index: '43',
                timezone: 'pm',

                label: '21:30'
            },

            {
                startTime: '22:00',
                endTime: '22:30',
                status: 'unavailable',
                index: '44',
                timezone: 'pm',

                label: '22:00'
            },
            {
                startTime: '22:30',
                endTime: '23:00',
                status: 'unavailable',
                index: '45',
                timezone: 'pm',

                label: '22:30'
            },

            {
                startTime: '23:00',
                endTime: '23:30',
                status: 'unavailable',
                index: '46',
                timezone: 'pm',

                label: '23:00'
            },
            {
                startTime: '23:30',
                endTime: '24:00',
                status: 'unavailable',
                index: '47',
                timezone: 'pm',

                label: '23:30'
            }
        ]
    }
];
