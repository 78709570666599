import React, { useEffect, useState } from 'react';
import { MenuItem, Select, Typography, Stack } from '@mui/material';
import { getLangFullName } from 'utils/multiLangUtils';
import EditOptionsModal from './EditOptionsModal';
import { DARK_EMOJI, LIGHT_EMOJI } from 'assets/emojies';

export default function CustomOptionsTable({
  data,
  handleClick,
  setData,
  show,
  handleEdit,
  showEditOptionModal,
  setshowEditOptionModal,
  multiLangOptionTextForEdit,
  setMultiLangOptionTextForEdit,
  setOptionText,
  setOptionBiengEdited,
  optionBeingEdited,
  handleEditOptionSubmit,
  pForEdit,
  setPEdit,
  isPainScoreQuestion
}) {
  const [showConditional, setShowConditional] = useState(false);

  const handleMultiLangOptionText = (lang, content) => {
    let arrayToSet = [...multiLangOptionTextForEdit];

    arrayToSet.forEach((arr) => {
      if (arr.language === lang) {
        if (lang === 'en') {
          setOptionText(content);
        }
        arr.content = content;
      }
    });
    setMultiLangOptionTextForEdit(arrayToSet);
  };

  useEffect(() => {
    if (show) {
      setShowConditional(true);
    } else setShowConditional(false);
  }, [show]);

  const closeEditModal = () => {
    setshowEditOptionModal(false);
    setOptionBiengEdited(null);
  };

  return (
    <div>
      <div className="row p-2 bold" style={{ background: '#d4f2ff' }}>
        <div className="col">Index</div>
        <div className="col">Option</div>
        <div className="col">P Value</div>
        {showConditional && <div className="col">P Value (For Condition)</div>}
        {isPainScoreQuestion && <div className="col">Emoji</div>}
        <div className="col">Action</div>
      </div>
      {data.map((e, index) => (
        <div key={index} className="row font-14 p-2 bd align-items-center" style={{ minHeight: 60 }}>
          <div className="col">{index + 1}</div>
          <div className="col" style={{ wordWrap: 'break-word' }}>
            {e?.multiLanguageOption?.map((opt, i) => (
              <div key={i}>{getLangFullName(opt.language) + ': ' + opt.content}</div>
            ))}
          </div>
          <div className="col" id={`highlight${index}`}>
            {e.code}
          </div>
          {showConditional && (
            <div className="col">
              <Select
                defaultValue={e.conditionalCode}
                onChange={(f) => {
                  let opt = [...data];
                  opt[index]['conditionalCode'] = f.target.value;
                  setData(opt);
                }}
              >
                <MenuItem disabled value="">
                  <em>Select Value of P</em>
                </MenuItem>
                {['P1', 'P2', 'P3', 'P4', 'P5'].map((d) => (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {isPainScoreQuestion && (
            <div className="col">
              <Stack direction="row" gap={2} alignItems="center">
                <Stack alignItems="center">
                  <img
                    src={LIGHT_EMOJI[e?.painScoreDetails?.selectedEmojiForLightMode] || e?.painScoreDetails?.emojiUrlForLightMode}
                    alt="light_emoji"
                    width={24}
                    height={24}
                    className="rounded-circle"
                  />
                  <Typography variant="caption">Light</Typography>
                </Stack>
                <Stack alignItems="center">
                  <img
                    src={DARK_EMOJI[e?.painScoreDetails?.selectedEmojiForDarkMode] || e?.painScoreDetails?.emojiUrlForDarkMode}
                    alt="dark_emoji"
                    width={24}
                    height={24}
                    className="rounded-circle"
                  />
                  <Typography variant="caption">Dark</Typography>
                </Stack>
              </Stack>
            </div>
          )}
          <div className="col d-flex">
            <Typography className="delete-question-btn" onClick={() => handleClick(e)}>
              Delete
            </Typography>
            <Typography className="edit-question-btn" onClick={() => handleEdit(e)}>
              Edit
            </Typography>
          </div>
        </div>
      ))}
      {showEditOptionModal && (
        <EditOptionsModal
          optionBeingEdited={optionBeingEdited}
          multiLangOptionTextForEdit={multiLangOptionTextForEdit}
          handleMultiLangOptionText={handleMultiLangOptionText}
          pForEdit={pForEdit}
          setPEdit={setPEdit}
          handleEditOptionSubmit={handleEditOptionSubmit}
          closeEditModal={closeEditModal}
          isPainScoreQuestion={isPainScoreQuestion}
        />
      )}
    </div>
  );
}
