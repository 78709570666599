import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import '../../../../../assets/scss/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { listOfUser } from 'store/redux/actions/user';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { clearDoctorApointment, getDoctorApointmentListingById } from 'store/redux/actions/doctor';
import { formatedDate, formatedTime, Log } from 'views/utilities/helper';
const columns = [
    {
        name: 'Id',
        selector: (row) => row.id,
        width: '100px'
    },
    {
        name: 'User Name',
        selector: (row) => row.username
    },
    {
        name: 'Apointment Date',
        selector: (row) => row.date
    },
    {
        name: 'Apointment Time',
        selector: (row) => row.time,
        width: '220px'
    },
    {
        name: 'Status',
        selector: (row) => row.status
    },
    {
        name: 'Action',
        selector: (row) => row.action
    }
];

function DoctorList({ id }) {
    const dispatch = useDispatch();
    const { userList } = useSelector((state) => state.user);
    const { doctorsListing, apointmentListing } = useSelector((state) => state.doctor);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    const makeRow = (userArr) => {
        var arr =
            Array.isArray(userArr) && userArr.length > 0
                ? userArr.map((data, id) => ({
                      id: id + 1,
                      username: (
                          <div className="date">
                              <h5>{data?.user?.firstname}</h5>
                          </div>
                      ),

                      date: (
                          <div className="date">
                              <h5>{formatedDate(data.created_at)}</h5>
                          </div>
                      ),

                      time: (
                          <div className="date">
                              <h5>{formatedTime(data.created_at)}</h5>
                          </div>
                      ),

                      status: <button className="active-btn">{data?.call_status}</button>,
                      action: <div className="d-flex align-items-center"></div>
                  }))
                : [];

        Log('Data', arr);
        setUsers(arr);
    };

    useEffect(() => {
        // setLoading(false);
        if (id) {
            dispatch(getDoctorApointmentListingById(id));
        }
        return () => {
            dispatch(clearDoctorApointment());
        };
    }, [id]);

    useEffect(() => {
        // setLoading(false);
        if (apointmentListing) {
            makeRow(apointmentListing);
        } else {
            dispatch(getDoctorApointmentListingById(id));
        }
    }, [apointmentListing]);
    return (
        <div className="Order-Details">
            <DataTable columns={columns} data={users} pagination={true} highlightOnHover />
        </div>
    );
}

export default DoctorList;
