import { Constants } from 'constants/Constants';
import request from 'utils/request';
import { Log } from 'views/utilities/helper';
import makeToast from 'views/utilities/Toast';
import { GET_ALL_QUESTION_DISEASEID, CLEAR_QUESTION_LIST } from '../action.constants';

export const getQuestionByDisease = async (inputData) => {
  try {
    const res = await request.post(Constants.getQuestionByDisease_api_url, inputData);
    // const { message, status, data } = res.data;
    // if (!status) throw res.data;

    if (res) {
      // dispatch({
      //     type: GET_ALL_QUESTION_DISEASEID,
      //     payload: data
      // });
      return res.data;
    }
  } catch (e) {
    makeToast('getQuestionByDisease error', e.message);
  }
};

export const clearQuestionList = () => (dispatch) => {
  dispatch({
    type: CLEAR_QUESTION_LIST
  });
};

export const createQuestions = async (data) => {
  try {
    let user = JSON.parse(localStorage.getItem('user'));
    const res = await request.post(Constants.createQuestion_api_url, { ...data, createdByHospital: user.hospital || null });
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('create Questions error', e);
    makeToast('error', e.message);
  }
};

export const deleteQuestionOption = async (data) => {
  try {
    const res = await request.post(Constants.deleteQuestionOption_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('delete  Questions option error', e);
    makeToast('error', e.message);
  }
};

export const updateQuestions = async (data) => {
  try {
    const res = await request.post(Constants.updateQuestion_api_url, { ...data, isDeleted: false });
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('create Questions error', e);
    makeToast('error', e.message);
  }
};

export const deleteQuestion = async (data) => {
  try {
    const res = await request.post(Constants.deleteQuestion_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('create Questions error', e);
    makeToast('error', e.message);
  }
};

export const getQuestionById = async (id) => {
  try {
    const res = await request.post(Constants.getQuestionById, { id });

    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('create Questions error', e);
    makeToast('error', e.message);
  }
};

export const attachQuestionByOption = async (data) => {
  try {
    const res = await request.post(Constants.attachQuestionByOption_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('Attach  Questions option error', e);
    makeToast('error', e.message);
  }
};

export const detachQuestionByOption = async (data) => {
  try {
    const res = await request.post(Constants.detachQuestionByOption_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('Detach  Questions option error', e);
    makeToast('error', e.message);
  }
};

export const attachDetachQuestionByQuestion = async (data) => {
  try {
    const res = await request.post(Constants.attachDetachQuestionByQuestion_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log(' Attach_Detach  Questions option error', e);
    makeToast('error', e.message);
  }
};

export const detachQuestion = async (data) => {
  try {
    const res = await request.post(Constants.detachQuestion_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log(' detachQuestion  Questions  error', e);
    makeToast('error', e.message);
  }
};

export const duplicatePathway = async (data) => {
  try {
    const res = await request.post(Constants.duplicatePathway_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('There is something wrong while pathway duplication', e);
    makeToast('error', e.message);
  }
};
export const deletePathway = async (data) => {
  try {
    const res = await request.post(Constants.deletePathway_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('There is something wrong while pathway duplication', e);
    makeToast('error', e.message);
  }
};

export const getQuestionByDiseaseIDAndVersion = async (data) => {
  try {
    const res = await request.post(Constants.getQuestionByDiseaseIDAndVersion_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    Log('There is something wrong while pathway duplication', e);
    makeToast('error', e.message);
  }
};

export const getPScore = async () => {
  try {
    const res = await request.get(Constants.getPScore_api_url);
    if (res) {
      return res.data?.data;
    }
  } catch (e) {
    Log('There is something wrong', e);
    makeToast('error', e.message);
  }
};

export const getEcdsDataByEthnicityCategory = async () => {
  try {
    const res = await request.get(Constants.getEcdsDataByEthnicCategory);
    if (res) {
      return res.data?.data;
    }
  } catch (e) {
    Log('There is something wrong', e);
    makeToast('error', e.message);
  }
};

export const getEcdsRefferalStatuses = async () => {
  try {
    const res = await request.get(Constants.getEcdsReferralStatues_api_url);
    if (res) {
      return res.data?.data;
    }
  } catch (e) {
    Log('There is something wrong', e);
    makeToast('error', e.message);
  }
};
