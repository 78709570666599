// assets
import { IconTypography, IconShadow, IconWindmill } from '@tabler/icons';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MedicationIcon from '@mui/icons-material/Medication';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// constant
const icons = {
  IconTypography,
  PersonOutlineIcon,
  IconShadow,
  IconWindmill,
  MedicationIcon,
  BookOnlineIcon
};
// const isAdmin = useSelector((state) => state.auth.user.role) === 'admin';
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const adminUtilities = () => {
  const { t } = useTranslation();
  return {
    id: 'utilities',
    type: 'group',
    children: [
      {
        id: 'user-list',
        title: t('User_List'),
        type: 'item',
        url: '/userlist',
        icon: icons.PersonOutlineIcon,
        breadcrumbs: false
      },
      {
        id: 'hospital-list',
        title: t('Hospital_List'),
        type: 'item',
        url: '/hospital',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      },
      {
        id: 'devices-list',
        title: t('Devices_List'),
        type: 'item',
        url: '/hospital-devices',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      },
      {
        id: 'question-disease',
        title: t('Clinical_Pathways'),
        type: 'item',
        url: '/question-list',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      },
      {
        id: 'disease',
        title: t('Pathway_Listing'),
        type: 'item',
        url: '/pathway-list',
        icon: icons.MedicationIcon,
        breadcrumbs: false
      },
      {
        id: 'public-health-questions',
        title: 'Public Health Questions',
        type: 'item',
        url: '/public-health-questions',
        icon: icons.PersonOutlineIcon,
        breadcrumbs: false
      },
      {
        id: 'admin-list',
        title: t('Admin_List'),
        type: 'item',
        url: '/admin-list',
        icon: icons.PersonOutlineIcon,
        breadcrumbs: false
      }
    ]
  };
};
export default adminUtilities;
