import { Constants } from 'constants/Constants';
import moment from 'moment';
import request from 'utils/request';
import { Log } from 'views/utilities/helper';
import makeToast from 'views/utilities/Toast';
import { DOCTORS_LIST, USER_LIST, CLEAR_DOCTOR_APOINTMENT, DOCTOR_APOINTMENTS_LIST, DOCTOR_DATA } from '../action.constants';
import { selectedApointments } from '../user/index';
export const getAllDoctorsListing = () => async (dispatch) => {
    Log('getAllDoctorsListing');
    try {
        const res = await request.get(Constants.getDoctorsList_api_url);
        const { message, status, data } = res.data;
        if (!status) throw res.data;
        if (status) {
            Log('Data Response', data?.data);
            dispatch({
                type: DOCTORS_LIST,
                payload: data
            });
        }
    } catch (e) {
        Log('getAllDoctorsListing error', e);

        // makeToast("getAllUserListing error", e.message);
    }
};

export const getDoctorApointmentListingById = (id) => async (dispatch) => {
    Log('getDoctorApointmentListing');
    try {
        const res = await request.post(Constants.getApointmentsByDoctorId_api_url, { id });
        const { message, status, data } = res.data;
        if (!status) throw res.data;
        if (status) {
            Log('Data getDoctorApointmentListingById Response', data?.data);
            dispatch({
                type: DOCTOR_APOINTMENTS_LIST,
                payload: data
            });
        }
    } catch (e) {
        Log('getDoctorApointmentListing error', e);

        // makeToast("getAllUserListing error", e.message);
    }
};

export const updateDoctorsApointment = (userData) => async (dispatch) => {
    Log('updateDoctorsApointment call', userData);

    try {
        const res = await request.post(Constants.updateDoctor_api_url, userData);
        const { message, status, data } = res.data;

        if (!status) throw res.data;
        if (status) {
            Log('Data', data);
            makeToast('success', 'Appointments Updated Successfully!');
            dispatch(getAllDoctorsListing());
            dispatch(getDoctorAvailabiltyById({ id: userData?.id }));
        }
    } catch (e) {
        Log('updateDoctorsApointment error ', e);
    }
};

export const getDoctorAvailabiltyById = (userData) => async (dispatch) => {
    Log('getDoctorAvailabiltyById call', userData);

    try {
        const res = await request.post(Constants.getDoctorDataById_api_url, userData);
        const { message, status, data } = res.data;
        if (!status) throw res.data;
        if (status) {
            let completeData = { ...data?.doctor, avalibility: makeData(data?.doctor) };
            Log('getDoctorAvailabiltyById Data', completeData);
            dispatch({
                type: DOCTOR_DATA,
                payload: completeData
            });
            let doc = makeData(completeData);
            // Log('Selected Apointmnets Date =>', doc);
            dispatch(selectedApointments(doc));
        }
    } catch (e) {
        Log('getDoctorAvailabiltyById error ', e);
    }
};

export const createDoctor = (userdata, close) => async (dispatch) => {
    Log('createDoctor');
    try {
        const res = await request.post(Constants.createDoctor_api_url, userdata);
        const { message, status, data } = res.data;
        if (!status) throw res.data;
        if (status) {
            Log('Data createDoctor Response', data?.data);
            dispatch(getAllDoctorsListing());
            close();
        }
    } catch (e) {
        Log('createDoctor error', e);
        makeToast('error', e.message);
    }
};

export const clearDoctorApointment = () => async (dispatch) => {
    dispatch({
        type: CLEAR_DOCTOR_APOINTMENT
    });
};

const makeData = (data) => {
    Log('maker Data Arg=', data);
    let pushArr = [];

    for (let i = 0; i < data?.avalibility?.length; i++) {
        let slotsArr = [
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '00:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '00:30',
                status: 'unavailable',
                index: '0',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '00:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '01:00',
                status: 'unavailable',
                index: '1',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '01:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '01:30',
                status: 'unavailable',
                index: '2',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '01:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '02:00',
                status: 'unavailable',
                index: '3',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '02:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '02:30',
                status: 'unavailable',
                index: '4',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '02:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '03:00',
                status: 'unavailable',
                index: '5',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '03:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '03:30',
                status: 'unavailable',
                index: '6',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '03:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '04:00',
                status: 'unavailable',
                index: '7',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '04:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '04:30',
                status: 'unavailable',
                index: '8',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '04:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '05:00',
                status: 'unavailable',
                index: '9',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '05:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '05:30',
                status: 'unavailable',
                index: '10',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '05:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '06:00',
                status: 'unavailable',
                index: '11',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '06:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '06:30',
                status: 'unavailable',
                index: '12',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '06:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '07:00',
                status: 'unavailable',
                index: '13',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '07:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '07:30',
                status: 'unavailable',
                index: '14',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '07:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '08:00',
                status: 'unavailable',
                index: '15',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '08:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '08:30',
                status: 'unavailable',
                index: '16',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '08:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '09:00',
                status: 'unavailable',
                index: '17',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '09:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '09:30',
                status: 'unavailable',
                index: '18',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '09:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '10:00',
                status: 'unavailable',
                index: '19',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '10:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '10:30',
                status: 'unavailable',
                index: '20',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '10:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '11:00',
                status: 'unavailable',
                index: '21',
                timezone: 'am'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '11:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '11:30',
                status: 'unavailable',
                index: '22',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '11:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '12:00',
                status: 'unavailable',
                index: '23',
                timezone: 'am'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '12:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '12:30',
                status: 'unavailable',
                index: '24',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '12:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '01:00',
                status: 'unavailable',
                index: '25',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '13:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '01:30',
                status: 'unavailable',
                index: '26',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '13:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '14:00',
                status: 'unavailable',
                index: '27',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '14:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '14:30',
                status: 'unavailable',
                index: '28',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '14:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '15:00',
                status: 'unavailable',
                index: '29',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '15:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '15:30',
                status: 'unavailable',
                index: '30',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '15:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '16:00',
                status: 'unavailable',
                index: '31',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '16:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '16:30',
                status: 'unavailable',
                index: '32',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '16:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '17:00',
                status: 'unavailable',
                index: '33',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '17:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '17:30',
                status: 'unavailable',
                index: '34',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '17:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '18:00',
                status: 'unavailable',
                index: '35',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '18:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '18:30',
                status: 'unavailable',
                index: '36',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '18:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '19:00',
                status: 'unavailable',
                index: '37',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '19:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '19:30',
                status: 'unavailable',
                index: '38',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '19:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '20:00',
                status: 'unavailable',
                index: '39',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '20:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '20:30',
                status: 'unavailable',
                index: '40',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '20:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '21:00',
                status: 'unavailable',
                index: '41',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '21:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '21:30',
                status: 'unavailable',
                index: '42',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '21:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '22:00',
                status: 'unavailable',
                index: '43',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '22:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '22:30',
                status: 'unavailable',
                index: '44',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '22:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '23:00',
                status: 'unavailable',
                index: '45',
                timezone: 'pm'
            },

            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '23:00',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '23:30',
                status: 'unavailable',
                index: '46',
                timezone: 'pm'
            },
            {
                startTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '23:30',
                endTime: moment(data?.avalibility[i]?.dateOfAvailability).format('YYYY-MM-DD') + 'T' + '24:00',
                status: 'unavailable',
                index: '47',
                timezone: 'pm'
            }
        ];

        Log('Make Data', data?.avalibility[i]?.slots);
        let slotsData = data?.avalibility[i]?.slots?.map((slot, id) => {
            // Log('Make Data first', slot?.index);
            slotsArr[slot?.index] = slot;
        });
        Log('Make Data SlotsData', data?.avalibility[i]?.dateOfAvailability);
        pushArr.push({
            dateOfAvailability: data?.avalibility[i]?.dateOfAvailability,
            status: true,
            slots: slotsArr
        });
    }

    return pushArr;
};
