import { useCallback } from 'react';
import { Handle, Position, NodeToolbar } from 'reactflow';

const handleStyle = { left: 10 };

function OptionDeadNode(props) {
    const onChange = useCallback((evt) => {
        // console.log(evt.target.value);
    }, []);
    // console.log({ optionNode: props.data });

    return (
        <>
            <NodeToolbar isVisible={props.data.toolbarVisible} position={props.data.toolbarPosition}>
                <button
                    onClick={() => {
                        console.log(props.data);
                    }}
                >
                    delete
                </button>
                <button>copy</button>
                <button>expand</button>
            </NodeToolbar>
            <div
                onClick={() => {
                    console.log('Hello');
                }}
                className="dead-option-node"
            >
                <Handle type="target" position={Position.Top} />
                <div className="d-flex align-items-center justify-content-center">{props.data?.label}</div>
                <Handle type="source" position={Position.Bottom} id="b" />
            </div>
        </>
    );
}

export default OptionDeadNode;
