import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// routes
import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AuthorRoutes from './AuthorRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isAuthenticated, isSuperAdmin, user } = useSelector((state) => state.auth);
  if (user && user?.user?.userRole === 'author') {
    console.log('AUTHOR FOUND => ', user);
    return useRoutes([AuthorRoutes]);
  } else {
    console.log('NO AUTHOR FOUND => ', user);
    if (isAuthenticated && isSuperAdmin) {
      return useRoutes([AdminRoutes]);
    } else if (isAuthenticated) {
      return useRoutes([MainRoutes]);
    } else {
      return useRoutes([AuthenticationRoutes]);
    }
  }
}
