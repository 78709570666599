import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const initialState = {};

const middleware = [thunk];

const store = createStore(reducer, initialState, compose(applyMiddleware(...middleware)));
const persister = 'Free';

export { store, persister };
