import React, { useEffect, useState } from 'react';

import { Button, Typography, RadioGroup, FormControlLabel, Radio, Select, MenuItem } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import VectorImg from '../../../assets/images/Vector.png';
import UploadImg from '../../../assets/images/upload.svg';
import crossImg from '../../../assets/images/cross.svg';
import { createJiraTicket } from 'store/redux/actions/incidents';

const steps = [
  'Incident Details',
  'Incident Date and Time',
  'Detailed Description',
  'Reporting Person',
  'Location Information',
  'Incident Attachments',
  'Additional Information'
];

const ItIncidentFormModal = () => {
  const [itIncidentFormModal, setItIncidentFormModal] = useState(false);
  const userData = JSON.parse(localStorage.getItem('user'));

  //STEP 1
  const [incidentType, setIncidentType] = useState('Hardware Issue');
  const [otherIncidentType, setOtherIncidentType] = useState('');
  const [severityLevel, setSeverityLevel] = useState('');

  //STEP 2
  const [incidentDate, setIncidentDate] = useState(new Date());
  const [incidentTime, setIncidentTime] = useState(new Date());

  //STEP 3
  const [detailedDescription, setDetailedDescription] = useState('');

  //STEP 4
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [departmentUnit, setDepartmentUnit] = useState('');

  //STEP 5
  const [edLocation, setEdLocation] = useState('');
  const [otherEdLocation, setOtherEdLocation] = useState('');
  const [specificAreaRoom, setSpecificAreaRoom] = useState('');

  //STEP 7
  const [isImpactingPatientCare, setIsImpactingPatientCare] = useState('yes');
  const [additionalComments, setAdditionalComments] = useState('');

  //UTILS
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const submitData = async () => {
    try {
      const dateAndTime = new Date(
        incidentDate.getFullYear(),
        incidentDate.getMonth(),
        incidentDate.getDate(),
        incidentTime.getHours(),
        incidentTime.getMinutes()
      );

      const objToSend = {
        issueDetails: {
          fields: {
            project: {
              key: 'DUM'
            },
            issuetype: {
              name: 'Bug'
            },
            summary: incidentType === 'other' ? otherIncidentType : incidentType,
            description: `Name: ${name}\nContact-Number: ${contactNumber}\nEmail: ${email}\nDate & Time: ${dateAndTime}\nCategory: Bug\nDepartment: ${departmentUnit}\nED-Location: ${edLocation === 'other' ? otherEdLocation : edLocation
              }\nHas impacted on patient care: ${isImpactingPatientCare}\nAdditional comments: ${additionalComments}\nDetailed Description: ${detailedDescription}`
          }
        },
        hospital: userData.hospital,
        user: userData._id,
        category: 'Bug',

        dateTime: dateAndTime,
        areaOfIncident: specificAreaRoom,
        severityLevel,
        name,
        contactNumber,
        email,
        departmentUnit,
        edLocation,
        isImpactingPatientCare,
        additionalComments,
        issuetype: "IT",
        description: detailedDescription
      };

      console.log('OBJ TO SEND => ', objToSend);

      const res = await createJiraTicket(objToSend);
      console.log('RES => ', res);
      setItIncidentFormModal(false);
      clearStates();
    } catch (error) {
      console.log('ERROR => ', error);
    }
  };

  const handleNext = () => {
    if (isLastStep) {
      submitData();
      setItIncidentFormModal(false);
      setActiveStep(0);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const _renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Incident Details
              </Typography>
              <div className="form-group">
                <label className="mb-2">
                  <strong>Incident Type</strong>
                </label>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={incidentType}
                  onChange={(e) => {
                    setIncidentType(e.target.value);
                  }}
                >
                  <FormControlLabel value="Hardware Issue" control={<Radio className="py-1" />} label="Hardware Issue" />
                  <FormControlLabel value="Opto App Issue" control={<Radio className="py-1" />} label="Opto App Issue" />
                  <FormControlLabel value="Network Issue" control={<Radio className="py-1" />} label="Network Issue" />
                  <FormControlLabel value="Data Security Breach" control={<Radio className="py-1" />} label="Data Security Breach" />
                  <FormControlLabel value="other" control={<Radio className="py-1" />} label="Other" />
                </RadioGroup>
                {incidentType === 'other' && (
                  <input
                    type="text"
                    className="form-control mt-4"
                    value={otherIncidentType}
                    onChange={(e) => setOtherIncidentType(e.target.value)}
                  />
                )}
              </div>
              <div className="form-group">
                <label className="mb-2">
                  <strong>Severity Level</strong>
                </label>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={severityLevel}
                  name="radio-buttons-group"
                  onChange={(e) => setSeverityLevel(e.target.value)}
                >
                  <FormControlLabel value="Critical (system down)" control={<Radio className="py-1" />} label="Critical (system down)" />
                  <FormControlLabel
                    value="High (major functionality impaired)"
                    control={<Radio className="py-1" />}
                    label="High (major functionality impaired)"
                  />
                  <FormControlLabel
                    value="Medium (minor functionality impaired)"
                    control={<Radio className="py-1" />}
                    label="Medium (minor functionality impaired)"
                  />
                  <FormControlLabel value="Low (cosmetic issue)" control={<Radio className="py-1" />} label="Low (cosmetic issue)" />
                </RadioGroup>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4  mb-5 pb-5 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Incident Date and Time
              </Typography>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>Incident Date</strong>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={new Date(incidentDate).toISOString().split('T')[0]}
                  onChange={(e) => {
                    setIncidentDate(new Date(e.target.value));
                  }}
                />
              </div>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>Incident Time</strong>
                </label>

                <input
                  type="time"
                  className="form-control"
                  value={incidentTime.toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                  onChange={(e) => {
                    const timeString = e.target.value;
                    const currentDate = new Date();
                    const timeParts = timeString.split(':');
                    currentDate.setHours(parseInt(timeParts[0], 10));
                    currentDate.setMinutes(parseInt(timeParts[1], 10));
                    setIncidentTime(currentDate);
                  }}
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 mb-5 pb-5 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '10px' }}>
                Detailed Description
              </Typography>
              <Typography variant="body1" sx={{ color: '#585858', marginBottom: '10px' }}>
                Please provide a detailed description of the incident, including what you were doing when it occurred, any error messages
                received, and the impact on your workflow.
              </Typography>
              <div className="form-group">
                <textarea
                  style={{ height: '109px' }}
                  type="text"
                  rows="5"
                  className="form-control"
                  value={detailedDescription}
                  onChange={(e) => setDetailedDescription(e.target.value)}
                />
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Reporting Person
              </Typography>
              <div className="form-group">
                <label className="mb-2">
                  <strong>Name</strong>
                </label>
                <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="form-group">
                <label className="mb-2">
                  <strong>Email Address</strong>
                </label>
                <input
                  type="mail"
                  className="form-control"
                  placeholder="email@opto.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="mb-2">
                  <strong>Contact Number</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="+1 000 000 000"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="mb-2">
                  <strong>Department/Unit</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Department"
                  value={departmentUnit}
                  onChange={(e) => setDepartmentUnit(e.target.value)}
                />
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Location Information
              </Typography>
              <div className="form-group">
                <label className="mb-2">
                  <strong>ED Location</strong>
                </label>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={edLocation}
                  name="radio-buttons-group"
                  onChange={(e) => setEdLocation(e.target.value)}
                >
                  <FormControlLabel value="Main ED" control={<Radio className="py-1" />} label="Main ED" />
                  <FormControlLabel value="Pediatric ED" control={<Radio className="py-1" />} label="Pediatric ED" />
                  <FormControlLabel value="other" control={<Radio className="py-1" />} label="Other" />
                </RadioGroup>
                {edLocation === 'other' && (
                  <input
                    type="text"
                    className="form-control mt-2"
                    value={otherEdLocation}
                    onChange={(e) => setOtherEdLocation(e.target.value)}
                  />
                )}
              </div>
              <div className="form-group">
                <label className="mb-2">
                  <strong>Specific Area or Room</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Area/Room"
                  value={specificAreaRoom}
                  onChange={(e) => setSpecificAreaRoom(e.target.value)}
                />
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4  mb-5 pb-5  w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '10px' }}>
                Incident Attachments
              </Typography>
              <Typography variant="body1" sx={{ color: '#585858', marginBottom: '40px' }}>
                You can attach any relevant screenshots, error logs, or files to help us understand the issue better.
              </Typography>
              <div className="form-group">
                <div className="Input_upload_area Input_upload_area_2 d-flex flex-column gap-3  justify-content-center align-items-center cursor-pointer">
                  <div className="Input_upload_area_content d-flex flex-column gap-3  justify-content-center align-items-center cursor-pointer">
                    <img
                      src={UploadImg}
                      alt="Preview Image"
                      id="previewImage"
                      className="drop-zoon__preview-image cursor-pointer"
                      draggable="false"
                    />
                    <Typography variant="body2" sx={{ color: '#36AFFF', marginBottom: '10px' }}>
                      Drag & Drop File here or upload
                    </Typography>
                  </div>
                  <input type="file" id="fileInput" className="Input_upload_area_file-input cursor-pointer" accept="image/*" />
                </div>
              </div>
            </div>
          </>
        );
      case 6:
        return (
          <>
            <div className="form-group d-flex flex-column gap-0 mt-4 w-100">
              <Typography variant="h3" sx={{ color: '#034562', marginBottom: '20px' }}>
                Additional Information
              </Typography>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong>Is this incident impacting patient care?</strong>
                </label>
                <Select className="w-100 Select" value={isImpactingPatientCare} onChange={(e) => setIsImpactingPatientCare(e.target.value)}>
                  <MenuItem value="yes">
                    <em>Yes</em>
                  </MenuItem>
                  <MenuItem value="no">
                    <em>No</em>
                  </MenuItem>
                </Select>
              </div>
              <div className="form-group">
                <label>
                  <strong>Additional Comments</strong>
                </label>
                <Typography variant="body2" sx={{ color: '#034562', marginBottom: '10px' }}>
                  Please provide any additional information or comments you think would be helpful in resolving this incident.
                </Typography>
                <textarea
                  style={{ height: '109px' }}
                  type="text"
                  rows="5"
                  className="form-control"
                  value={additionalComments}
                  onChange={(e) => setAdditionalComments(e.target.value)}
                />
              </div>
            </div>
          </>
        );
      default:
        return <div>Not Found</div>;
    }
  };

  const clearStates = () => {
    setIncidentType('Hardware Issue');
    setOtherIncidentType('');
    setSeverityLevel('');
    setIncidentDate(new Date());
    setIncidentTime(new Date());
    setDetailedDescription('');
    setName('');
    setEmail('');
    setContactNumber('');
    setDepartmentUnit('');
    setEdLocation('');
    setOtherEdLocation('');
    setSpecificAreaRoom('');
    setIsImpactingPatientCare('yes');
    setAdditionalComments('');
    setActiveStep(0);
  };

  return (
    <>
      <div
        onClick={() => setItIncidentFormModal(true)}
        className="d-flex justify-content-start align-items-center gap-4 w-75 cursor-pointer"
      >
        <div className="icon">
          <img src={VectorImg} alt="img" width="60" />
        </div>
        <div className="d-flex gap-1 flex-column justify-content-start">
          <Typography className="p-0 m-0" variant="h4" sx={{ color: '#36AFFF' }}>
            Report and IT Incident
          </Typography>
          <Typography className="p-0 m-0" variant="subtitle2" sx={{ color: '#034562' }}>
            Navigating tech troubles to make you secure and informed.
          </Typography>
        </div>
      </div>
      <Modal className="modal-opto" centered show={itIncidentFormModal}>
        <div className="modal-content">
          <Modal.Header>
            <Modal.Title className="modal-title">IT Incident Form</Modal.Title>
            <Button
              onClick={() => {
                setItIncidentFormModal(false);
                setActiveStep(0);
              }}
              className="close"
            >
              <img src={crossImg} alt="img" width="20" height="20" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center h-100 align-items-center">
              <div className="auth-form col-md-10">
                {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>Step {activeStep + 1}</Typography> */}
                {_renderStepContent(activeStep)}

                <div className="d-flex gap-3 justify-content-center mb-4">
                  {activeStep !== 0 && (
                    <button className="btn btn-light text-secondary btn-block px-4" onClick={handleBack}>
                      Back
                    </button>
                  )}
                  <button className="btn btn-primary btn-block px-4" onClick={handleNext}>
                    {isLastStep ? 'Submit' : 'Next'}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ItIncidentFormModal;
