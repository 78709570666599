import {
    CLEAR_DOCTOR_APOINTMENT,
    DOCTORS_LIST,
    DOCTOR_APOINTMENTS_LIST,
    DOCTOR_DATA,
    USER_LIST
} from 'store/redux/actions/action.constants';
import { Log } from 'views/utilities/helper';

const initial_state = {
    doctorsListing: null,
    apointmentListing: null,
    doctor_data: null
};

export default function (state = initial_state, action) {
    switch (action.type) {
        case DOCTORS_LIST:
            return {
                ...state,
                doctorsListing: action.payload
            };
        case DOCTOR_APOINTMENTS_LIST:
            return {
                ...state,
                apointmentListing: action.payload
            };
        case CLEAR_DOCTOR_APOINTMENT:
            return {
                ...state,
                apointmentListing: null
            };
        case DOCTOR_DATA:
            Log('DOCTOR_DATA', action.payload);
            return {
                ...state,
                doctor_data: action.payload
            };

        default:
            return state;
    }
}
